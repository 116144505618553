@import "src/assets/scss/variable";

.items-perpage-wrapper {
  display: flex;
  width: 290px;
  justify-content: flex-end;
}

.items-perpage {
  width: auto;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -webkit-appearance: none;
  border: none;
  height: 38px;
  padding: 0.25rem 0.75rem;
}

.items-link {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.75rem;
  border-right: 1px solid $blue-lighten-95;
  background-color: $white;
  height: 38px;
  font-size: 14px;
}

.page-link {
  border: none;
  color: $gray-base;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  margin-left: 0;
}

.link-page-left {
  border-left: 1px solid $blue-lighten-95;
}

.link-page-right {
  border-right: 1px solid $blue-lighten-95;
}

.page-notlink {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: $gray-base;
  font-size: 14px;
  background-color: $white;
}

.pagination > li > a:hover {
  background-color: $white;
}
.page-link i {
  font-size: 16px;
}
.page-link span {
  font-size: 16px;
}
