@import "src/assets/scss/variable";

.button-banner {
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .back-icon {
    font-size: 21px;
  }
  .back-icon img {
    width: 24px;
    margin-right: 20px;
  }
  .customBtn {
    margin-bottom: 10px;
    padding: 4px 16px;
    min-width: 140px;
    height: 30px;
  }
}
