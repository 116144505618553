@import "src/assets/scss/variable";

body,
html * {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $gray-lighten-70;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-lighten-40;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $gray-lighten-20;
  }
}
