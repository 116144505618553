/*------ toggle CSS ---------*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
  top: 10px;
  left: 10px;
}
.round-toggle {
  line-height: 45px;
  color: $white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: $blue-base;
}
.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-lighten-40;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
}

input:checked + .slider {
  background-color: $green-darken-15;
  color: $pure-white;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(40px);
}

.slider:after {
  content: "OFF";
  color: $gray-base;
  font-size: 12px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: "ON";
  position: absolute;
  left: 37%;
}

/*--------- END --------*/
/*------ toggle lg CSS ---------*/
.switch-lg {
  position: relative;
  display: inline-block;
  width: 126px;
  height: 20px;
  top: 0;
  left: 0;
}
.round-toggle {
  line-height: 45px;
  color: $white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
}
.switch-lg input {
  display: none;
}

.slider-lg {
  height: 32px;
  width: 120px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 2px solid $gray-lighten-30;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-lg:before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 0;
  bottom: 1px;
  background-color: $gray-lighten-30;
  border: 10px solid white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 48%;
  box-shadow: 0 0 0 2px $gray-lighten-30;
}

input:checked + .slider-lg {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid $green-darken-15;
  font-size: 12px;
}

input:focus + .slider-lg {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-lg:before {
  -webkit-transform: translateX(89px);
  -ms-transform: translateX(89px);
  transform: translateX(89px);
  background-color: $green-darken-15;
  border: 10px solid white;
  box-shadow: 0 0 0 2px $green-darken-15;
}

.slider-lg:after {
  content: "Off";
  color: $gray-lighten-30;
  font-size: 12px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
  font-family: Verdana, sans-serif;
}

input:checked + .slider-lg:after {
  content: "On";
  color: $green-darken-15;
  background: transparent;
  position: absolute;
  left: 37%;
}
.slider-lg:lang(es_ES):after {
  content: "Apagado";
  color: $gray-lighten-30;
  font-size: 12px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
  font-family: Verdana, sans-serif;
}

input:checked + .slider-lg:lang(es_ES):after {
  content: "En";
  color: $green-darken-15;
  background: transparent;
  position: absolute;
  left: 37%;
}

input:disabled + .slider-lg:lang(es_ES):after {
  content: "Apagado";
  color: $gray-lighten-20;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
}

.slider-lg:lang(fr_CA):after {
  content: "De";
  color: $gray-lighten-30;
  font-size: 12px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
  font-family: Verdana, sans-serif;
}

input:checked + .slider-lg:lang(fr_CA):after {
  content: "Sur";
  color: $green-darken-15;
  background: transparent;
  position: absolute;
  left: 37%;
}

input:disabled + .slider-lg:lang(fr_CA):after {
  content: "De";
  color: $gray-lighten-20;
  font-size: 12px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
}

input:disabled + .slider-lg {
  background-color: transparent;
  opacity: 0.38;
}

input:disabled + .slider-lg:before {
}

input:disabled + .slider-lg:after {
  color: $gray-lighten-20;
  font-size: 12px;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

input.default-on:disabled + .slider-lg:before {
  -webkit-transform: translateX(89px);
  -ms-transform: translateX(89px);
  transform: translateX(89px);
  color: $gray-lighten-20;
  box-shadow: 0 0 0 2px $gray-lighten-30;
  background-color: $gray-lighten-30;
  border: 10px solid white;
}

input.default-on:disabled + .slider-lg:lang(fr_CA):after {
  content: "Sur";
}

input.default-on:disabled + .slider-lg:lang(es_ES):after {
  content: "En";
}

input.default-on:disabled + .slider-lg:after {
  content: "On";
  color: $gray-lighten-20;
  background: transparent;
  position: absolute;
  left: 37%;
}
/*--------- END --------*/
