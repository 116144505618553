@import "src/assets/scss/variable";

.checkboxs {
  margin-left: 18px;

  input[type="checkbox"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 22px;

    &::before,
    &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 2px solid $gray-lighten-20;
      border-radius: 4px;
      left: 0px;
      background: $pure-white;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px;
    }

    &::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid $pure-white;
      border-bottom: 2px solid $pure-white;
      color: $pure-white;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  input[type="checkbox"] {
    + label::after {
      content: none;
      color: $gray-darken-20;
    }

    &:checked + label::after {
      content: "";
      color: $gray-darken-20;
    }

    &:checked + label::before {
      background: $blue-base;
      border: 0;
    }
  }
}

/***************END**************/

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
label.mat-checkbox-layout {
  margin-bottom: 0.25em;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox-checked.checkboxContainer {
  background-color: $gray-lighten-60;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  height: 16px;
  width: 16px;
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  margin-right: 16px;
}
.horizontalLine {
  height: 2em;
  border: 1px solid $gray-lighten-50;
}
label {
  margin-bottom: 0;
}
.mat-mdc-checkbox.mat-accent {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mdc-checkbox__checkmark {
    border: 2px solid $gray-lighten-40;
    border-radius: 2px;
    background-color: $white;
  }
  .mdc-checkbox__checkmark-path {
    color: white;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-checkmark-path {
    stroke: $white;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
}
.mat-checkbox-disabled.mat-mdc-checkbox-checked .mat-checkbox-background {
  background: none;
}

.mat-mdc-icon-button {
  padding: 0;
  min-width: 0;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 0;
}
.mat-mdc-icon-button .mat-icon {
  line-height: 14px;
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent
  .mat-checkbox-ripple
  .mat-ripple-element {
  background-color: transparent;
}

/*******************
Checkbox
*******************/

.checkbox {
  padding-left: 20px;

  label {
    display: block;
    padding-left: 5px;
    position: relative;

    &::before {
      -o-transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      background-color: $white;
      border-radius: 1px;
      border: 1px solid $border;
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      top: 3px;
      margin-left: -20px;
      position: absolute;
      transition: 0.3s ease-in-out;
      width: 17px;
      outline: none;
    }

    &::after {
      color: $gray-darken-40;
      display: inline-block;
      font-size: 11px;
      height: 16px;
      left: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      position: absolute;
      top: 3px;
      width: 16px;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    position: absolute;
    left: 0px;
    outline: none;

    &:disabled + label {
      opacity: 0.65;
    }
  }

  input[type="checkbox"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: none;
    }
  }

  input[type="checkbox"]:checked + label {
    &::after {
      content: "\f00c";
      font-family: "FontAwesome";
    }
  }

  input[type="checkbox"]:disabled + label {
    &::before {
      background-color: $blue-lighten-95;
      cursor: not-allowed;
    }
  }
}

.checkbox.checkbox-circle {
  label {
    &::before {
      border-radius: 50%;
    }
  }
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single {
  label {
    height: 17px;
  }
}

.checkbox-primary {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $primary;
      border-color: $primary;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-danger {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $red-lighten-15;
      border-color: $red-lighten-15;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-info {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $blue-base;
      border-color: $blue-base;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-warning {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $orange-lighten-30;
      border-color: $orange-lighten-30;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-success {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $green-lighten-30;
      border-color: $green-lighten-30;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-purple {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $purple;
      border-color: $purple;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-red {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $red-lighten-15;
      border-color: $red-lighten-15;
    }

    &::after {
      color: $white;
    }
  }
}

.checkbox-inverse {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $gray-darken-40;
      border-color: $gray-darken-40;
    }

    &::after {
      color: $white;
    }
  }
}
