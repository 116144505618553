@import "src/assets/scss/variable";

/*******************
Global Styles
*******************/

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

h2,
.h2,
h2.h2 {
  font-size: 1.5rem;
}

.flexed-container {
  display: flex !important;
}

.page-wrapper-test {
  color: $gray-lighten-60;
}

body {
  background: $gray-lighten-60;
  font-family: $regularfont;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  color: $gray-base;
  font-weight: 300;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html {
  background: $gray-lighten-60;
  position: relative;
  min-height: 100%;
  height: 100%;
}

.policyNametext {
  text-transform: capitalize;
}

.cdk-global-scrollblock {
  position: static;
  width: initial;
  overflow-y: inherit;
}

.cdk-global-scrollblock body {
  overflow: hidden;
}

.no-padding {
  padding: 0;
}

.no-padding-sides {
  padding-left: 0;
  padding-right: 0;
}

.main-contents {
  display: flex;
  flex-direction: column;
  margin-left: 7.5rem;
}

@media (max-width: 767px) {
  .main-contents {
    margin-left: 0;
  }
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: $gray-base;

  &:hover,
  &:focus {
    color: $blue-base;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.noDataImg img {
  width: auto;
  height: 200px;
  margin-top: 104px;
  margin-bottom: 48px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.img-rounded {
  border-radius: $radius;
}

/*******************
  Paddings
  *******************/

html body {
  .p-0 {
    padding: 0px;
  }

  .p-10 {
    padding: 10px;
  }

  .p-20 {
    padding: 20px;
  }

  body .p-30 {
    padding: 30px;
  }

  .p-l-0 {
    padding-left: 0px;
  }

  .p-l-5 {
    padding-left: 5px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .p-l-20 {
    padding-left: 20px;
  }

  .p-l-24 {
    padding-left: 24px;
  }

  .p-l-32 {
    padding-left: 24px;
  }

  .p-r-0 {
    padding-right: 0px;
  }

  .p-r-4 {
    padding-right: 4px;
  }

  .p-r-5 {
    padding-right: 5px;
  }

  .p-r-8 {
    padding-right: 8px;
  }

  .p-r-24 {
    padding-right: 24px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .p-r-20 {
    padding-right: 20px;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .p-r-40 {
    padding-right: 40px;
  }

  .p-t-0 {
    padding-top: 0px;
  }

  .p-t-8 {
    padding-top: 8px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-t-48 {
    padding-top: 30px;
  }

  .p-b-0 {
    padding-bottom: 0px;
  }

  .p-b-4 {
    padding-bottom: 4px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-8 {
    padding-bottom: 8px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-16 {
    padding-bottom: 16px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-b-40 {
    padding-bottom: 40px;
  }

  .p-b-48 {
    padding-bottom: 40px;
  }
}

/*******************
  Margin
  *******************/

html body {
  .m-0 {
    margin: 0px;
  }

  .m-l-5 {
    margin-left: 5px;
  }

  .m-l-10 {
    margin-left: 10px;
  }

  .m-l-15 {
    margin-left: 15px;
  }

  .m-l-16 {
    margin-left: 16px;
  }

  .m-l-20 {
    margin-left: 20px;
  }

  .m-l-30 {
    margin-left: 30px;
  }

  .m-l-40 {
    margin-left: 40px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-r-15 {
    margin-right: 15px;
  }

  .m-r-20 {
    margin-right: 20px;
  }

  .m-r-30 {
    margin-right: 30px;
  }

  .m-r-40 {
    margin-right: 40px;
  }

  .m-t-0 {
    margin-top: 0px;
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-t-32 {
    margin-top: 32px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-b-0 {
    margin-bottom: 0px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-8 {
    margin-bottom: 8px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-16 {
    margin-bottom: 16px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-24 {
    margin-bottom: 24px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-32 {
    margin-bottom: 32px;
  }

  .m-b-40 {
    margin-bottom: 40px;
  }

  .m-b-72 {
    margin-bottom: 72px;
  }
}

/*******************
  vertical alignment
  *******************/

html body {
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }
}

/*******************
  Border
  *******************/

html body {
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

/*******************
  Background Colors
  *******************/

html body {
  .bg-primary {
    background-color: $blue;
  }

  .bg-success {
    background-color: $green;
  }

  .bg-info {
    background-color: $green-lighten-15 !important; //Dashboard top visited sites prgressbar background color
  }

  .bg-warning {
    background-color: $red-lighten-30 !important; //Dashboard top blocked sitesprgressbar background color
  }

  .bg-danger {
    background-color: $pink;
  }

  .bg-megna {
    background-color: $purple-dark;
  }

  .bg-theme {
    background-color: $blue-base;
  }

  .bg-inverse {
    background-color: $gray-darken-40;
  }

  .bg-purple {
    background-color: $perwinkle;
  }

  .bg-light-primary {
    background-color: $blue-lighten-95;
  }

  .bg-light-success {
    background-color: $green-lighten-90;
  }

  .bg-light-info {
    background-color: $blue-lighten-90;
  }

  c .bg-light-extra {
    background-color: $blue-lighten-95;
  }

  .bg-light-warning {
    background-color: $orange-lighten-90;
  }

  .bg-light-danger {
    background-color: $red-lighten-90;
  }

  .bg-light-inverse {
    background-color: $red-lighten-95;
  }

  .bg-light {
    background-color: $blue-lighten-95;
  }

  .bg-white {
    background-color: $white;
  }

  .bg-orange {
    background-color: $orange;
  }
}

/*******************
List-style-none
******************/

ul.list-style-none {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;

    a {
      color: $gray-base;
      padding: 8px 0px;
      display: block;
      text-decoration: none;

      &:hover {
        color: $blue-base;
      }
    }
  }
}

.favicon {
  height: 40px;
  width: 40px;
  margin-right: 24px;
  min-width: 40px;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 50%;
  background-position: center;
  display: flex;
  z-index: 1;
  position: relative;
  background-color: $gray-lighten-60;
}

.favicon-sm {
  height: 32px;
  width: 32px;
  margin-right: 24px;
  min-width: 40px;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 50%;
  background-position: center;
  z-index: 1;
  position: relative;
  background-color: $gray-lighten-60;
  min-width: 24px;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.favicon-title {
  height: 32px;
  width: 32px;
  margin-right: 24px;
  min-width: 40px;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 50%;
  background-position: center;
  z-index: 1;
  position: relative;
  background-color: $gray-lighten-20;
  min-width: 24px;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

/*******************
textarea
******************/

textarea {
  resize: none;
}

/*******************/

/*widgets -app*/

/*******************/

.css-bar:after {
  z-index: 1;
}

.css-bar > i {
  z-index: 10;
}

/*******************
Custom-select
******************/

/*******************
Layouts
******************/

/*******************/

/*single column*/

/*******************/

.single-column {
  .left-sidebar {
    display: none;
  }

  .page-wrapper {
    margin-left: 0px;
  }
}

/*******************/

/*Documentation*/

/*******************/

.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

ul.common li {
  display: inline-block;
  line-height: 40px;
  list-style: outside none none;
  width: 48%;
}

/*******************/

/*pace-js*/

/*******************/

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $blue-lighten-15;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}

.progress {
  height: auto;
  min-height: 6px;
  margin-bottom: 6px;
}

.paper {
  i {
    padding: 6px;
  }

  span {
    padding: 5px;
  }
}

.bottom-chart {
  border-right: 1px solid $gray-lighten-50;
}

.m-t-80 {
  margin-top: 80px;
}

.min-container-ht {
  min-height: 80vh;
}

.sc-container-padding {
  padding: 0px 60px;
}

/* Remove styling in timepicker library */
.timepicker .form-control.is-invalid {
  padding: 0;
  background: none;
}

.cursor-pointer {
  cursor: pointer;
}
nav.navbar {
  height: 3rem;
}
.navbar {
  margin-bottom: 0;
  padding: 0;
}
.nav-link {
  padding: 0.2rem 1rem;
}
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $blue-base;
}
.mat-mdc-tab-group {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    min-width: 115px;
    padding: 0 24px;
    &-active {
      opacity: 1;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-content {
      color: $gray-darken-40;
      font-family: $mediumfont;
      font-weight: 500;
      opacity: 1;
      &:hover,
      &:focus {
        color: $gray-darken-40;
      }
    }
  }
}
.background-white {
  background-color: white;
}
.mat-icon {
  font-family: "Material Icons" !important;
}
td.mat-mdc-cell:first-of-type {
  padding-right: 8px !important;
}
td.mat-mdc-cell:not(:first-of-type) {
  padding: 5px 8px !important;
}
//mat-dialog with Next and Previous arrows for DetailView component
.custom-dialog-container.navigation-btns .mat-mdc-dialog-container {
  position: relative;
  overflow: initial;
  padding: 40px;
}
.custom-dialog-container.overflow .mat-mdc-dialog-container {
  overflow: initial;
}
.custom-dialog-container .mat-mdc-dialog-container {
  padding: 0;
  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mat-mdc-dialog-content {
    margin: 0;
  }
}
.weblink-form {
  padding: 0 10px;
}
.nowrap {
  white-space: nowrap;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
.flex-between {
  display: flex !important;
  justify-content: space-between !important;
}
.top-section {
  display: flex;
  justify-content: space-between;
}
.sticky {
  position: sticky;
  top: -1px;
  z-index: 10;
  position: -webkit-sticky;
}
.back-icon svg {
  width: 1rem;
  height: 1rem;
  margin-top: 33%;
}
mat-icon svg {
  vertical-align: top;
}
mat-icon.sm {
  width: 1rem;
  height: 1rem;
}
.vertical-align-inherit {
  vertical-align: inherit;
}
.mat-icon.active-message-icon {
  width: 20px;
  height: 21px;
}
.mat-icon.sm-icon {
  width: 20px;
  height: 20px;
}

.disabledOnCore {
  &:hover {
    cursor: not-allowed;
  }

  * {
    &:hover {
      cursor: not-allowed;
    }
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-paginator-container .mat-button-disabled {
  opacity: 0.5;
}

.rostered-students .mat-mdc-tab-link {
  min-width: 100px;
}

.markdown-clipboard-button {
  display: inline-block;
  position: relative;
  border: 0;
  background: transparent;
  color: $gray-lighten-60;
  font-family: "Nucleo";
  &:after {
    content: "\ea60";
  }

  &.copied:after {
    font-family: $regularfont;
    content: "Copied!";
  }
}
