@import "src/assets/scss/variable";

.mat-mdc-dialog-content {
  overflow: initial !important;
}
.custom-dialog-container-border-radius .mat-mdc-dialog-container {
  box-shadow: 0 15px 18px 0 rgba(10, 31, 68, 0.12),
    0 0 1px 0 rgba(10, 31, 68, 0.1);
  border-radius: 8px;
  overflow-y: auto;
}
.modal-container {
  padding: 1em;

  .page-header,
  .search-section {
    padding: 0 2em;
    width: 100%;
    position: relative;
  }

  .submit-section {
    margin-top: 2em;
    display: flex;
    justify-content: flex-end;
  }
  .r-btn {
    justify-content: flex-end !important;
  }
  .m-btn {
    justify-content: space-between !important;
  }
  .r-btn,
  .m-btn {
    button {
      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }
  .s-btn {
    button {
      margin: 0;
    }
  }

  .no-scroll {
    overflow: hidden;
  }
  .modalCancelBtn {
    min-width: 24px;
    height: 24px;
    font-family: $semi-boldfont;
    cursor: pointer;
    user-select: none;
    text-align: center;
    height: auto;
    line-height: 20px;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    background-color: transparent;
    color: $gray-base;
    border: none;
    padding: 0;
    margin: 1vh 1vw 0 0;
    i {
      color: $gray-base;
      font-size: 24px;
    }
    &:focus {
      outline: none;
    }
  }
  .modalContent {
    height: 100%;
    padding: 0 2em 1em;

    h3 {
      margin-bottom: 1.5rem;
    }
  }
  .dialog-header {
    margin-bottom: 32px;
  }
  .modalBtn {
    display: inline-block;
    padding: 11px 16px;
    margin: 10px;
    min-width: 141px;
    height: 44px;
    font-family: $semi-boldfont;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    text-align: center;
    height: auto;
    line-height: 20px;
    vertical-align: middle;
    white-space: normal;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;

    &.-schedule {
      background-color: $gray-lighten-70;
      border: none;
      font-size: 14px;
      width: 181px;
      height: 40px;
      a {
        color: $blue-base;
        i {
          padding-right: 10px;
          color: $blue-base;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 1rem;

    label {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .mat-mdc-dialog-container {
      padding: 14px;
    }
    .modalContent {
      padding: 0 14px 14px 14px;
    }
  }
}
