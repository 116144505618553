@import "src/assets/scss/variable";

header {
  .navbar {
    margin-bottom: $spacer;
  }

  #navbar-menu {
    .navbar-nav {
      align-items: center;
    }
  }
  .appSettingsDropdown {
    border-radius: 4px;
    box-shadow: 0 15px 18px 0 rgba(10, 31, 68, 0.12),
      0 0 1px 0 rgba(10, 31, 68, 0.1);
    background-color: #ffffff;
    border: none;
    max-height: inherit;
  }
  .nav-link.dropdown-toggle {
    cursor: pointer;
  }
  .nav-logo {
    height: 35px;
  }
  .dropdown-toggle i {
    vertical-align: inherit;
    margin-left: 8px;
    text-align: right;
    float: none;
  }
  .dropdown-item .icon {
    color: #637484;
    padding: 0px 8px 0px 0px;
    position: relative;
    top: 3px;
    font-size: 14px;
  }
  .dropdown-toggle .icon-circle-08-2,
  .dropdown-toggle .icon-c-question {
    font-size: 24px;
  }
  .dropdown-toggle .icon-down-arrow {
    font-size: 8px;
    vertical-align: text-top;
  }
}

.actions-container {
  display: flex;
  margin: 1rem 0;
  .header-action {
    align-self: center;
    margin-bottom: 1rem;

    .sub-header-item {
      display: inline-block;
      font-size: 14px;
      margin-right: 1.5rem;
      &-value {
        color: $black;
      }
    }

    .back-icon {
      margin-right: 1rem;
      img,
      svg {
        width: 1rem;
        height: 1rem;
        margin-top: 33%;
      }
    }

    h2 {
      margin-bottom: 0;
      font-size: 1.5rem;
    }

    h2,
    h4 {
      max-width: 50ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .header-buttons {
    button {
      width: auto;
      // height: 32px;
      // padding: 2px 6px;
      margin: 0;
      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
  }
}
