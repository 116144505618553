@import "src/assets/scss/variable";

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio {
  &-ripple {
    display: none;
  }

  &-outer-circle {
    border-color: $gray-lighten-30 !important;
  }

  &-inner-circle {
    background-color: $blue-base !important;
  }

  &-label-content {
    margin-right: 1rem;
    color: #171b1f;
  }
  &-disabled {
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-outer-circle {
      border-color: rgba(0, 0, 0, 0.38) !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-inner-circle {
      background-color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}

.radio-label {
  color: $gray-darken-20;
  font-size: 16px;
}

.radioButton input[type="radio"] {
  position: absolute;
  opacity: 0;

  + .radio-label:before {
    content: "";
    background: $pure-white;
    border-radius: 100%;
    border: 2px solid $gray-lighten-40;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    margin: 0 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }

  &:checked + .radio-label:before {
    background-color: $blue-base;
    box-shadow: inset 0 0 0 4px white;
  }

  &:focus + .radio-label:before {
    outline: none;
    border: 2px solid $gray-lighten-40;
  }

  .radio-label:empty:before {
    margin-right: 0;
  }
}

/*******************
Radios
*******************/

.radio {
  padding-left: 20px;

  label {
    display: inline-block;
    padding-left: 5px;
    position: relative;

    &::before {
      -o-transition: border 0.5s ease-in-out;
      -webkit-transition: border 0.5s ease-in-out;
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $border;
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -20px;
      outline: none;
      position: absolute;
      transition: border 0.5s ease-in-out;
      width: 17px;
      outline: none;
    }

    &::after {
      -moz-transition: -moz-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.3s
        cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: $gray-darken-40;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 7px;
      left: 5px;
      margin-left: -20px;
      position: absolute;
      top: 5px;
      transform: scale(0, 0);
      transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      width: 7px;
    }
  }

  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none;

    &:disabled + label {
      opacity: 0.65;
    }
  }

  input[type="radio"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: none;
    }
  }

  input[type="radio"]:checked + label {
    &::after {
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }

  input[type="radio"]:disabled + label {
    &::before {
      cursor: not-allowed;
    }
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single {
  label {
    height: 17px;
  }
}

.radio-primary {
  input[type="radio"] + label {
    &::after {
      background-color: $primary;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $primary;
    }

    &::after {
      background-color: $primary;
    }
  }
}

.radio-danger {
  input[type="radio"] + label {
    &::after {
      background-color: $red-lighten-15;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $red-lighten-15;
    }

    &::after {
      background-color: $red-lighten-15;
    }
  }
}

.radio-info {
  input[type="radio"] + label {
    &::after {
      background-color: $blue-base;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $blue-base;
    }

    &::after {
      background-color: $blue-base;
    }
  }
}

.radio-warning {
  input[type="radio"] + label {
    &::after {
      background-color: $orange-lighten-30;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $orange-lighten-30;
    }

    &::after {
      background-color: $orange-lighten-30;
    }
  }
}

.radio-success {
  input[type="radio"] + label {
    &::after {
      background-color: $green-lighten-30;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $green-lighten-30;
    }

    &::after {
      background-color: $green-lighten-30;
    }
  }
}

.radio-purple {
  input[type="radio"] + label {
    &::after {
      background-color: $purple;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $purple;
    }

    &::after {
      background-color: $purple;
    }
  }
}

.radio-red {
  input[type="radio"] + label {
    &::after {
      background-color: $red-lighten-15;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $red-lighten-15;
    }

    &::after {
      background-color: $red-lighten-15;
    }
  }
}

.checkbox label,
.radio label {
  cursor: pointer;
}
