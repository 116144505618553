@import "src/assets/scss/variable";

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.va-mat-button-no-input {
  border: none;
  background: transparent;
  outline: none;
}

.va-mat-table-dragable-container {
  min-width: 200px;
}
.va-mat-table-dragable-container .dragable-row {
  width: 100%;
  line-height: 25px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.va-mat-table-dragable-container .dragable-row > div {
  border-bottom: 1px solid $gray-lighten-50;
  padding: 0.25em 0;
}
.va-mat-table-drag-preview mat-icon,
.va-mat-table-drag-preview mat-checkbox,
.va-mat-table-dragable-container .dragable-row mat-icon,
.va-mat-table-dragable-container .dragable-row mat-checkbox {
  vertical-align: middle;
  height: auto;
}
.va-mat-table-dragable-container .dragable-row mat-icon {
  opacity: 0.15;
  transition: opacity 0.5s;
  padding-left: 4px;
  color: #616161;
  cursor: grab;
  font-size: 17px;
}
.va-mat-table-dragable-container .dragable-row:hover mat-icon {
  opacity: 1;
}

.va-mat-table-drag-preview {
  padding: 4px 8px 4px 4px;
  cursor: grabbing !important;
  margin-top: -4px;
  margin-left: -4px;
  font-size: 14px;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
.mat-mdc-menu-content {
  width: 250px;
}
.va-mat-table-dragable-container .dragable-row mat-icon {
  opacity: 1.15;
  padding-left: 8px;
  margin-left: 8px;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0 !important;
}
.va-mat-table-dragable-container {
  .btn-primary-outline {
    width: 200px;
    height: 32px;
    margin: 8px 24px 0 16px;
    padding: 3px 30px 5px 35px;
    border-radius: 20px;
    border: solid 1px $gray-lighten-20;
  }
}
