@import "src/assets/scss/variable";

/*******************
Megamenu
******************/

.mega-dropdown {
  position: static;
  width: 100%;

  .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px;
    // background: url(../../assets/images/background/megamenubg.jpg)no-repeat right bottom $white;
  }

  ul {
    padding: 0px;

    li {
      list-style: none;
    }
  }

  .carousel-item .container {
    padding: 0px;
  }

  .nav-accordion {
    .card {
      margin-bottom: 1px;
    }

    .card-header {
      background: $white;

      h5 {
        margin: 0px;

        a {
          text-decoration: none;
          color: $gray-base;
        }
      }
    }
  }
}
