@import "src/assets/scss/variable";

/*Card-noborders*/

.card-no-border {
  .card {
    border: 0px;
    border-radius: 8px;
  }

  .card-outline-success {
    border-top: 1px solid $green-darken-15;
    border-radius: 0 0 8px 8px;
  }

  .card-outline-information {
    border-top: 1px solid $red-base;
    border-radius: 0 0 8px 8px;
  }

  .card-outline-bugs {
    border-top: 1px solid $red-lighten-15;
    border-radius: 0 0 8px 8px;
  }

  .sidebar-footer {
    background: $white;
  }

  .left-sidebar {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  }

  .left-sidebar,
  .sidebar-nav {
    background: $white;
    z-index: 60;
  }

  @media (max-width: 1168px) {
    .left-sidebar,
    .sidebar-nav {
      z-index: 60;
    }
  }

  .sidebar-nav > ul > li > a.active {
    background: $blue-lighten-95;
  }
}

.card-no-border .shadow-none {
  box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
  background: $white;
}

.card-no-border .card-group .card {
  border: 1px solid $border;
}

.card-header {
  background-color: #dae6e8;
}

.form-card {
  background-color: $white;
  padding: 3vh 2vw !important;
  border-radius: 8px;

  .collection-img {
    display: flex;
    justify-content: center;
    img {
      height: 200px;
    }
  }
  .sites {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sites-status {
    font-size: 16px;
    color: $gray-lighten-20;
  }
  .action {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    align-items: center;
  }

  .header {
    align-items: center;
  }

  .form-group,
  .role-container {
    margin-top: 2vh;
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }
  .password-container {
    display: flex;
  }
  .password-container > div {
    margin-right: 1rem;
  }
  .form-check {
    display: flex;
    margin-top: 2vh;
  }
  .input-group-append {
    position: absolute;
    top: 10px;
    right: 11px;
  }
  .password-field {
    position: relative;
  }

  .label-text {
    margin-bottom: 0;
  }
  .form-group {
    margin: 0;
    margin-top: 0.5rem;

    label {
      margin-bottom: 0;
    }
  }
  form > div {
    margin-bottom: 2px;
    line-height: 1;
  }

  &.box {
    flex-grow: 1;
    padding: 7px 7px 0px 7px;
    max-height: 150vh;
    overflow-y: auto;
  }
}
