@import "./variable";

.mdc-snackbar.mat-mdc-snack-bar-container {
  padding: 0;
  margin: 24px;
  border: 0;
  color: $gray-base;
  box-shadow: none;
  border-radius: 8px;
  .mat-mdc-snack-bar-label {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }
  .mdc-snackbar__surface {
    min-width: 35ch;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
}
.snack-bar-container-error,
.snack-bar-container-warning,
.snack-bar-container-success,
.snack-bar-container {
  padding: 0;
  border: 1px solid $red-base;
  color: $gray-base;
  background: $white;
  box-shadow: none;
  border-radius: 8px;
  .snackbarError,
  .snackbarWarning,
  .snackbarCheck {
    background-color: $red-base;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    padding: 5% 4%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    i {
      color: $white;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .text-baseline {
    padding: 4.5%;
    word-break: break-word;
  }
  .snackbarX {
    color: $gray-base;
    font-size: 24px;
  }
  .mat-mdc-raised-button:not([class*="mat-elevation-z"]) {
    height: auto;
    box-shadow: none;
    padding: 4.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .mat-mdc-raised-button {
    background: transparent;
    border-radius: 8px;
  }
}
.snack-bar-container-warning {
  border: 1px solid #877017;
  .snackbarWarning {
    background-color: #feecbc;
    i {
      color: #877017;
    }
  }
}
.snack-bar-container {
  border: 1px solid $green-darken-15;
  .snackbarCheck {
    background: $green-darken-15;
    i {
      color: $white;
    }
  }
}
.mat-mdc-snack-bar-handset {
  width: auto;
}
.cdk-focused {
  background: inherit;
  box-shadow: none;
}
.cdk-mouse-focused {
  background: inherit;
  box-shadow: none;
}
.mat-mdc-raised-button:not([disabled]):active:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}
