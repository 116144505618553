@import "src/assets/scss/variable";

.config-table-container {
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .rows:last-child {
    padding-bottom: 24px;
  }
  .sys-bx {
    margin: 0.5rem 0;
    background-color: $white;
    .sys-header {
      min-height: 40px;
      margin-bottom: 16px;
      background-color: $blue-lighten-90;
      padding: 10px 32px;
      margin-right: -1px;
      div {
        font-family: $mediumfont;
      }
    }
    .rules {
      padding: 8px 32px;
      width: 29rem;
    }
    .form-group {
      margin-bottom: 0;
    }
    .timezone-selector,
    .location-selector {
      .dropdown-item:disabled {
        cursor: not-allowed;
        opacity: 0.65;
        pointer-events: all;
      }
      .dropdown-toggle i {
        padding-top: 0.2em;
      }
      #dropdownCategory {
        margin-right: 0;
        margin-left: 0;
      }
      select::-ms-expand {
        display: none;
      }
      #dropdownCategory.select-border {
        border: 1px solid $gray-lighten-40;
        text-align: left;
        width: 25rem;
        height: 2.5rem;
        border-radius: 4px;
      }
      .dropdown-btn-width {
        width: 19rem;
      }
      .icon-c-question {
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }
  @media (max-width: 991px) {
    .rules,
    #dropdownCategory.select-border {
      width: 100% !important;
    }
  }
}
