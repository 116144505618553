@import "src/assets/scss/variable";

.multicheck-component {
  nav {
    padding: 16px;
    background: $white;
    color: $white;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 200px;
  }
  .multicheck-table-wrapper {
    display: flex;
    background: $white;
    padding: 1rem;
    max-width: 70vw;
    max-height: 80vh;
    margin: 0 40px;

    .side-nav-wrapper {
      max-width: 15vw;
    }
  }

  main {
    max-height: calc(100vh - 50px);
    overflow-y: scroll;
    width: 100%;
    padding-right: 0.5em;

    .multicheck-headers {
      display: flex;
      justify-content: space-between;
      background-color: $blue-lighten-95;
    }

    .multicheck-item {
      width: 200px;
      padding: 0.3rem;
      margin: auto;
    }
    .header-group {
      display: flex;
      width: 100%;
      height: 48px;
      .header {
        padding: 0.4rem;
        font-weight: 500;
      }
      .header.multicheck-item {
        font-size: 14px;
        font-family: $semi-boldfont;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: $gray-darken-40;
        padding-left: 1.5rem;
        margin: auto 0;
      }
    }

    .section-header {
      height: 40px;
      padding: 10px;
      padding-left: 1.5rem;
      margin: auto 0;
      background-color: $blue-lighten-90;
      font-size: 14px;
      font-family: $mediumfont;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $gray-darken-40;
    }
    .header-group {
      background-color: $gray-lighten-70;
    }

    .multicheck-row {
      display: flex;
      border-bottom: 2px solid $blue-lighten-90;
      padding: 0.5em 0;
    }
    .multicheck-item {
      width: 200px;
      padding: 0.3rem;
      margin-left: 0.5em;
      color: $gray-darken-10;
      font-size: 14px;
      font-family: $mediumfont;
      padding-left: 1.5rem;
      margin: auto 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    ::ng-deep
      .mat-checkbox-disabled.mat-checkbox-checked
      .mat-checkbox-background {
      background-color: #b0b0b0 !important;
    }
  }

  nav a.active {
    color: $blue-base;
    margin-left: -0.5em;
    padding-left: 0.5em;
    background: url(/assets/indicator.svg) no-repeat;
    background-size: contain;
  }

  .header-group {
    display: flex;
    width: 100%;
    .header {
      padding: 0.4rem;
      font-weight: 500;
    }
    .header.header-item {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: $gray-darken-40;
      padding-left: 1em;
    }
  }
  .navigation-list {
    list-style-type: none;
    padding: 0;
    li {
      padding: 10px 16px;
      font-size: 14px;
      color: $blue-base;
      line-height: 18px;
      height: 48px;
      border-left: 0;
      font-family: "Metropolis-SemiBold", sans-serif;
    }
    a {
      margin: 12px 1px;
      white-space: nowrap;
      width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      color: $gray-base;
    }
  }
}

@media screen and (min-width: 1600px) {
  .multicheck-table-wrapper {
    max-width: 100vw !important;
  }
}
