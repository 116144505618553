@import "src/assets/scss/variable";

// Main sidebar for the page
.main-sidebar {
  /*******************
Main sidebar
******************/

  .left-sidebar {
    position: fixed;
    width: 120px;
    height: 100%;
    top: 0;
    left: 0;
    background: $white;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 1s ease;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* make scrollbar transparent */
    }
  }

  /*******************
sidebar navigation
******************/

  .scroll-sidebar {
    padding-bottom: 60px;
    padding-top: 20px;
    background: $white;

    .sidebaricon {
      img {
        display: block;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  .sidebar-nav {
    background: $white;
    display: block;
    margin-top: 24px;
    ul {
      margin: 0px;
      padding: 0px;
      overflow-y: scroll;
      max-height: 85vh;
      li {
        list-style: none;
        &:last-child {
          margin-bottom: 40px;
        }
        a {
          color: $gray-lighten-20;
          padding: 14px 0px;
          display: block;
          font-size: 14px;
          text-align: center;
          mat-icon svg * {
            stroke: $gray-lighten-20;
          }
          &.router-link-active,
          &:hover {
            color: $blue-base;
            mat-icon svg * {
              stroke: $blue-base;
            }
            i {
              color: $blue-base;
              text-align: center;
            }
            span {
              color: $blue-base;
            }
          }
          &:focus {
            color: $blue-base;
            i {
              color: $blue-base;
              text-align: center;
            }
          }
          &.router-link-active {
            font-weight: 300;
            color: $blue-base;
          }
          span {
            color: $gray-lighten-20;
            display: block;
            margin-top: 8px;
          }
        }
        ul {
          padding-left: 0;
          li a {
            padding: 50px 15px 16px 15px;
          }
          ul {
            padding-left: 10px;
          }
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: 0px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
    > ul > li {
      &.active > a {
        color: $blue-base;
        border-left: 8px solid $blue-base;
        border-radius: 2px;
        i,
        mat-icon {
          color: $blue-base;
          margin-left: -8px; //minimizing blue-border width
        }
        span {
          margin-left: -8px; //minimizing blue-border width
          color: $blue-base;
        }
        mat-icon svg * {
          stroke: $blue-base;
        }
        span.hide-menu {
          color: $blue-base;
        }
      }
    }
    > ul > li > a {
      i {
        width: 100%;
        font-size: 24px;
        display: block;
        color: $gray-lighten-20;
      }
      .label {
        float: right;
        margin-top: 6px;
      }
      &.active {
        font-weight: 400;
        background: $white;
        color: $blue-base;
      }
    }
  }

  .togglenav {
    position: relative;
    left: 112px;
  }

  .menu-24 {
    font-size: 14px;
    margin-top: 4px;
  }
  .toggler {
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 3;
    outline: none;
    position: relative;
    bottom: 48px;
  }

  .toggler .line {
    display: block;
    width: 24px;
    height: 3px;
    background-color: $gray-lighten-20;
    margin-bottom: 0.4rem;
    transition: 0.4s;
  }
  .cross {
    position: relative;
    right: 0;
    z-index: inherit;
  }
  .cross .line:nth-child(1) {
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .cross .line:nth-child(2) {
    opacity: 0;
  }

  .cross .line:nth-child(3) {
    transform: rotate(45deg) translate(-7px, -5px);
  }
  @media (max-width: 767px) {
    .left-sidebar {
      position: fixed;
      width: 120px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: inherit;
      z-index: 2;
      transform: translateX(-100%);
      transition: all 0.5s linear;
      opacity: 0.5;
    }
  }
  .nav-active {
    transform: translateX(0%);
    opacity: 1;
  }
  @media (min-width: 768px) {
    &.navbar .toggler {
      display: none;
    }
  }
}

.side-nav-and-content-container {
  display: -webkit-box;
  display: flex;
  .settings-wrapper {
    width: 100%;
    margin-left: 200px;
  }
}

// Smaller sidebar for individual components
.side-nav {
  ul.navigation li button.viewMore {
    text-align: right;
    display: block;
    padding: 10px 16px;
    font-size: 14px;
    color: $blue-base !important;
    line-height: 18px;
    font-family: "Metropolis-SemiBold", sans-serif;
  }

  .active {
    background-size: contain;
    background-color: $white;
    border-left: 4px solid $blue-base !important;
    border-radius: 2px;
    button {
      div {
        margin-left: -4px; //minimizing left-border width
        color: $blue-base;
      }
    }
  }

  .overwriteBtn {
    display: flex;
    justify-content: center;
  }

  .temp-class {
    display: flex;
    width: 200px;
  }

  .active button {
    color: $gray-darken-40 !important;
    /* adding blue color to the side nav for policy editor*/
  }

  .sticky {
    position: fixed;
    z-index: 60;
  }
  ul.navigationTopUl {
    list-style-type: none;
    padding: 0;
    position: relative;
    z-index: 55;
    margin-bottom: 0;
  }

  ul.navigation {
    list-style-type: none;
    padding: 0;
    position: relative;
    background: #f7fafc;
    z-index: 55;
  }

  ul.navigation li:not(.active):hover {
    background: $white;
  }

  .sidebar {
    width: 200px;
    height: 100vh;
    margin-top: 3rem;
  }

  ul.navigationTopUl li button {
    display: flex;
    border-bottom: none;
    width: 100%;
    color: $gray-base;
    font-size: 14px;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
  }

  ul.navigation li button {
    display: flex;
    border-bottom: none;
    width: 100%;
    height: 100%;
    color: $gray-base;
    font-size: 14px;
    background: transparent;
    padding: 0;
    margin: 0vh 24px;
    border: 0;
    align-items: center;

    &:focus {
      outline: none;
    }
  }

  ul.navigationTopUl li {
    height: 48px;
    border-left: 4px;
  }

  ul.navigation li {
    height: 48px;
    border-left: 0;
  }

  ul.navigation .defaultLi:last-child {
    border-bottom: 0;
  }

  .side-nav-item-text {
    margin: 12px 24px;
    white-space: nowrap;
    width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: lowercase;
    text-align: left;
  }
  .side-nav-item-text::first-letter {
    text-transform: uppercase;
  }

  .side-nav-item-text:hover {
    color: $gray-base;
  }

  .card-no-border .left-sidebar,
  .card-no-border .sidebar-nav {
    z-index: 60;
  }

  .custom-dialog-container {
    height: 100%;
  }

  .side-nav-wrapper {
    background-color: #f7fafc;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 1px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $gray-lighten-50;
    }
  }

  .dropdownMenu .dropdown-value .dropdown-text {
    padding-left: 16px;
  }
  @media (max-width: 767px) {
    .side-nav-wrapper {
      box-shadow: none;
      top: 45px;
      z-index: 1;
    }
  }
}
