@import "./variable.scss";

.date-picker {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    width: auto;
    max-width: 15rem;
  }
  mat-error {
    margin-top: 1rem;
    display: inline-block;
    &:first-of-type {
      margin-right: 0.5rem;
    }
  }

  .mat-date-range-input,
  .mat-form-field-type-mat-date-range-input.ng-invalid.ng-touched:not(form) {
    border-left: none !important;
  }

  .exclude-error-border {
    border-left: none;
  }
}
.custom-calendar-icon svg {
  width: 28px;
  height: 30px;
}
.custom-calendar-icon img {
  margin-bottom: 50%;
}
.action-bar {
  .date-picker {
    padding-top: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      color: $gray-lighten-20;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field.mat-form-field-invalid
      .mat-form-field-label
      .date-picker-label {
      color: #f44336 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      align-self: auto !important;
      width: 10.5rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      bottom: 1px;
      border-bottom: 1px solid $gray-lighten-40;
    }
    button.mat-mdc-icon-button {
      padding-left: 6px;
    }
  }
}
.mat-calendar-table-header th {
  padding: 0 !important;
}
.mat-calendar-content {
  padding: 0 !important;
}

.mat-calendar-body-selected,
.mat-calendar-body-range-start,
.mat-calendar-body-range-end {
  color: $gray-lighten-70 !important;
  div {
    color: $gray-lighten-70 !important;
  }
}

// Overrides
.mat-datepicker-content {
  position: relative !important;
  top: 2rem !important;
  right: 2rem !important;
  .mat-calendar {
    max-width: 40vw !important;
    max-height: 60vh !important;
  }
}
.mat-calendar-table {
  width: 90% !important;
  margin: auto !important;

  &-header {
    tr:first-of-type {
      background-color: #e6f2ff !important;
      th {
        font-weight: 500 !important;
        font-size: 1rem !important;
      }
    }
    &-divider::after {
      left: 0 !important;
      right: 0 !important;
    }
  }
}
.mat-calendar-controls {
  margin-bottom: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.ng-invalid.ng-touched .mat-form-field-wrapper {
  border-bottom: 1px solid theme-color("danger") !important;
}
.mat-date-range-input-container {
  .ng-invalid.ng-touched:not(form) {
    border-left: none;
  }
}

.time-selection {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  max-width: 100%;
  input {
    font-family: $mediumfont;
    color: $gray-darken-40;
    text-align: center;
    margin: 0 8px 0 0;
    padding: 7px 0;
    border-radius: 4px;
    border: solid 1px $gray-lighten-40;
  }
  .time-box {
    max-width: 50%;
  }
}
.time-input {
  font-family: $mediumfont;
  color: $gray-darken-40;
  text-align: center;
  margin: 0 8px 0 0;
  padding: 7px 2px;
  border-radius: 4px;
  border: solid 1px $gray-lighten-40;
  max-width: 4rem;
}
