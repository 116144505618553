@import "src/assets/scss/variable";

.popover-custom-class {
  background-color: $gray-darken-30;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: $gray-lighten-60;
  pointer-events: none;
  border: 0;
}

.popover-config-class {
  background-color: $gray-darken-30;
  font-size: 12px;
  line-height: 18px;
  color: $gray-lighten-60;
  pointer-events: none;
  border: 0;
}

.popover-config-class .arrow::after {
  border-top-color: $gray-darken-30;
}

.popover-custom-class-parents {
  background-color: $gray-darken-30;
  padding: 0.5rem 0.25rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray-lighten-60;
  pointer-events: none;
  border: 0;
}

.popover-custom-class-parents.bs-popover-top .arrow::after {
  border-top-color: $gray-darken-30;
}

.popover-custom-class-bell {
  background-color: $gray-darken-30;
  padding: 0.3rem 0.15rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $gray-lighten-60;
  pointer-events: none;
  border: 0;
}

.popover-custom-class-bell .arrow::after {
  border-top-color: $gray-darken-30 !important;
}

.popover-custom-class-auditor {
  background-color: $gray-darken-30;
  padding: 0.5rem 0.25rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray-lighten-60;
  pointer-events: none;
  border: 0;
}

.bs-popover-left > .arrow::after {
  border-left-color: $gray-darken-30 !important;
}

.popover-custom-class-auditor.bs-popover-bottom .arrow::after {
  border-top-color: $gray-darken-30 !important;
  border-left-color: $gray-darken-30 !important;
}

.popover-custom-class-ip {
  background-color: $gray-darken-30;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: $gray-lighten-60;
  width: 16em;
}

.popover-custom-class-disabledbell {
  background-color: $gray-darken-30;
  padding: 0.3rem 0.15rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray-lighten-60;
  // width: 16em;
  pointer-events: none;
  margin-bottom: 10px;
}

.popover-custom-class-disabledbell.bs-popover-top .arrow::after {
  border-top-color: $gray-darken-30;
}

.popover-custom-class-md {
  background-color: $gray-darken-30;
  width: 16em;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: $gray-lighten-60;
  pointer-events: none;
  border: 0;
}

.popover-custom-class-md.bs-popover-top .arrow::after {
  border-top-color: $gray-darken-30;
}

.popover-custom-class-md.bs-popover-right .arrow::after {
  border-right-color: $gray-darken-30;
}

.popover-custom-class-md.bs-popover-bottom .arrow::after {
  border-bottom-color: $gray-darken-30;
}

.popover-custom-class-info {
  background-color: $gray-darken-30;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $gray-lighten-60;
  width: 16em;
  pointer-events: none;
}

.popover-custom-class-info.bs-popover-top .arrow::after {
  border-top-color: $gray-darken-30;
}

.popover-custom-class-info.bs-popover-right .arrow::after {
  border-right-color: $gray-darken-30;
}

.popover-custom-class-info.bs-popover-bottom .arrow::after {
  border-bottom-color: $gray-darken-30;
}

.popover-custom-class.bs-popover-bottom .arrow::after {
  border-bottom-color: $gray-darken-30;
}

.popover-custom-class-bell.bs-popover-bottom .arrow::after {
  border-bottom-color: $gray-darken-30;
}

.popover-config-class.bs-popover-bottom .arrow::after {
  border-bottom-color: $gray-darken-30;
}

.popover-custom-class-ip .arrow::after {
  border-top-color: $gray-darken-30;
}

.popover-custom-class .arrow::after {
  border-top-color: $gray-darken-30;
}

.skipTooltip .popover-body {
  padding: 0;
}

.popover-body {
  color: $gray-lighten-60;
}
.mat-mdc-tooltip {
  font-family: $mediumfont;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $gray-lighten-50 !important;
  background-color: $gray-darken-30;
  margin-bottom: 0.5rem;
  .mdc-tooltip__surface {
    max-height: 400px;
    overflow-y: auto;
    word-wrap: break-word;
  }
}
