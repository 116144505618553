@import "src/assets/scss/variable";

.dropdown-menu-right {
  right: 10px !important;
}

.btn-dropdown {
  text-align: left;
  color: $gray-base;
  border-radius: 4px;
  font-family: $regularfont;
  font-size: 14px;
  margin: 0 10px;
  width: 200px;
  background-color: $pure-white;
  border-top: none;
  border-right: none;
  border-left: none;
  -webkit-outline: none;
  padding: 8px 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &:hover {
    color: $gray-darken-20;
    // border-bottom: 1px solid $gray-lighten-50;
  }
  &:focus {
    color: $gray-darken-20;
    outline: none;
    box-shadow: none;
  }
  &:last-child {
    margin-right: 0;
  }
  .btn-dropdown-value {
    //width: 10.2em;
    color: $gray-base;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
    &:hover {
      color: $gray-darken-20;
    }
  }
}

.btn-dropdown-noborder {
  border: none;
}
.dropdownMenu {
  width: auto;
  padding: 0;
  z-index: 40;
  border-radius: 4px;
  top: 100%;
  z-index: 1;
  box-shadow: 0 15px 18px 0 rgba(10, 31, 68, 0.12),
    0 0 1px 0 rgba(10, 31, 68, 0.1);
  .dropdown-value {
    color: $gray-base;
    font-size: 14px;
    font-family: $regularfont;
    text-align: left;
    padding: 15px 16px 0px 16px;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $blue-lighten-95;
    }

    .dropdown-text {
      border-bottom: 1px solid $gray-lighten-50;
      padding-bottom: 9px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 30ch;
    }
    &:last-child,
    &:last-child .dropdown-text {
      border-bottom: none;
      .dropdown-text {
        border-bottom: none;
      }
    }
  }
  .dropdown-menu {
    width: auto;
    padding: 1em 0;
    margin: 0;
    top: 47px;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0px 2px 5px 0px $gray-lighten-40;
    -moz-box-shadow: 0px 2px 5px 0px $gray-lighten-40;
    box-shadow: 0px 2px 5px 0px $gray-lighten-40;
  }
}
.search-container {
  padding: 16px 16px 8px;
  .search-input {
    min-height: 40px;
    display: initial;
    padding-left: 24px;
    padding-right: 15px;
    width: 100%;
    color: $gray-darken-40;
  }
  .icon-search {
    position: absolute;
    top: 26px;
    left: 16px;
    pointer-events: none;
  }
}
.empty-search {
  text-align: center;
  padding: 4px 4px 16px;
  display: block;
}
.dropdown-toggle {
  width: auto !important;
  .dropdown-text {
    max-width: 30ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.dropdown-toggle::after {
  border: none;
  content: none;
  color: transparent;
  background: transparent;
}

.dropdown-toggle i {
  vertical-align: inherit;
  margin-left: 8px;
  text-align: right;
  float: right;
}
.srh-btn {
  display: inline;
  position: absolute;
  padding-top: 0.5em;
  i {
    position: relative;
    top: 2px;
    background: transparent;
    font-size: 16px;
    color: $gray-lighten-30;
    &.icon-search {
      color: $gray-darken-10;
    }
  }
}

.date-range-input {
  background: $white url(/app/ang-7/dist/assets/images/calendar-date.svg)
    no-repeat;
  //background-position: ; add positioning in the component.ts to the calender to position it correctly inside your input.
  background-size: 16px;
  cursor: pointer;
}

.filterControl {
  background-color: transparent;
  margin-bottom: 8px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid $gray-lighten-40;
  border-radius: 0;
  font-size: 14px;
  line-height: 22px;
  color: $gray-lighten-40;
  &:focus {
    background-color: transparent;
    color: $gray-base;
    border: transparent;
    border-bottom: 1px solid $green-base;
    outline: 0;
    box-shadow: none;
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-size: 14px;
  text-align: center;
  color: $gray-darken-40;
  background-color: $pure-white;
  border-bottom: 2px solid $blue-base;
  border-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $gray-lighten-20;
  padding: 16px 20px;
  font-family: $mediumfont;
}
.nav-pills {
  background-color: $pure-white;
  border-radius: 4px;
}

.tab-content > .active {
  display: block;
  margin-top: 20px;
}

.form-control {
  color: $gray-lighten-40;
  min-height: 38px;
}

.badgeReports {
  display: inline-block;
  padding: 8px;
  margin-top: 16px;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.badge-blue {
    color: $blue-base;
    background-color: $blue-lighten-95;
    width: auto;
  }
  &.badge-purple {
    color: #9c6ade;
    background-color: #eaecfc;
    width: auto;
  }
  &.badge-green {
    color: $green-darken-15;
    background-color: $green-lighten-95;
    width: auto;
  }
  &.badge-orange {
    color: $orange-lighten-15;
    background-color: $orange-lighten-95;
    width: auto;
  }
  &.badge-red {
    color: $red-lighten-15;
    background-color: $red-lighten-95;
    width: auto;
  }
}

.dropdown-menu.show {
  max-height: 40vh;
  overflow-y: scroll;
}
.dropdown-menu.show::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.dropdown-menu.show::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.dropdown-menu.show::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-lighten-50;
}
//role dropdown

.app-role-width .role-dropdown .role-width {
  border: 1px solid #b0cceb;
  width: 24.5em;
  text-align: left;
}

.dropdown-btn-width {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 9.1em;
  display: inherit;
  color: $gray-darken-40;
}

.items-container {
  max-height: 40vh;
  overflow-y: auto;
  min-width: 240px;
}
.items-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.items-container::-webkit-scrollbar {
  width: 1px;
  background-color: #f5f5f5;
}
.items-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $gray-lighten-50;
}
select.custom-dropdown {
  font-family: $mediumfont;
  color: $gray-darken-40;
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  max-width: 5rem;
  margin: 0;
  padding: 7px 8px;
  border: solid 1px #b0cceb;
  border-radius: 4px;
  background-image: url("../scss/icons/icon-8-px-arrow@3x.png");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position-x: 86%;
  background-position-y: 10px;
  padding-right: 1.5rem;
}

.full-width.dropdown,
.full-width.dropup,
.full-width #dropdownCategory {
  width: 100% !important;
}
