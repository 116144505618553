@import "src/assets/scss/variable";

/*******************
File Upload
******************/

.fileupload {
  overflow: hidden;
  position: relative;

  input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
