/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// 3rd party libraries
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// Theme
@import "theme/theme";

// Thematic styles
@import "theme/theme-variables";
@import "./assets/scss/common";
@import "./assets/scss/variable";
@import "./assets/scss/typography";
@import "./assets/scss/header.scss";

// Individual components
@import "./assets/scss/dropdown";
@import "./assets/scss/button";
@import "./assets/scss/modal";
@import "./assets/scss/snack-bar";
@import "./assets/scss/custom-grid";
@import "./assets/scss/checkbox";
@import "./assets/scss/radio";
@import "./assets/scss/table";
@import "./assets/scss/action-bar";
@import "./assets/scss/scrollbar";
@import "./assets/scss/pagination";
@import "./assets/scss/badge";
@import "./assets/scss/blockquote";
@import "./assets/scss/cards";
@import "./assets/scss/file-upload";
@import "./assets/scss/forms";
@import "./assets/scss/megamenu";
@import "./assets/scss/notify";
@import "./assets/scss/popover";
@import "./assets/scss/rounds";
@import "./assets/scss/thumb";
@import "./assets/scss/wave";
@import "./assets/scss/column-sorter";
@import "./assets/scss/multicheck-table";
@import "./assets/scss/navigation";
@import "./assets/scss/config-table";
@import "./assets/scss/button-banner";
@import "./assets/scss/mat-select";
@import "./assets/scss/charts";
@import "./assets/scss/date-picker";
@import "./assets/scss/toggle";

// @import './assets/scss/';
@import "./assets/scss/icons/securly-font-icons/css/style.css";
@import "./assets/scss/fonts.scss";
@import "@ctrl/ngx-emoji-mart/picker";

/**
* Overrides - used to override nested component SCSS to avoid the use of ::ng-deep in this project
* Will apply application-wide unless a unique identifier is specified
*/
@import "./assets/scss/aichat-override.scss";
