/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-wrapper {
  padding: 0;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
  height: 0;
  margin: 0;
  padding-top: 0.5em !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-underline {
  display: none;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix {
  display: flex;
  align-self: flex-end;
  width: 225px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.top-selector .mat-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: url("./icons/icon-select-arrow-down.svg") !important;
  border-image-repeat: stretch !important;
  margin-top: 18px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-thumb {
  width: 14px !important;
  height: 15px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-thumb-container {
  top: 0 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-bar {
  width: 31px !important;
  height: 16px !important;
  background-color: $gray-lighten-30 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $green-darken-15 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $white !important;
}
.full-screen .mat-mdc-dialog-container {
  padding: 4px;
}
.zoom-out-icon,
.zoom-in-icon {
  cursor: pointer;
}
