/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
 @use "sass:map";
 @use '@angular/material' as mat;
 @import '../assets/scss/variable.scss';

$primary-palette: (
    160: $blue-darken-60,
    145: $blue-darken-45,
    130: $blue-darken-30,
    900: $blue-darken-15,
    800: $blue-darken-10,
    0: $blue-base,
    100: $blue-lighten-15,
    200: $blue-lighten-30,
    300: $blue-lighten-45,
    400: $blue-lighten-50,
    500: $blue-lighten-60,
    600: $blue-lighten-75,
    700: $blue-lighten-90,
    contrast: (
        50: rgba(white, 0.87),
    )
);

$sc-checkbox-primary: mat.define-palette($primary-palette, 0);
$sc-checkbox-accent: mat.define-palette($primary-palette, 0);


/* Checkbox theme */
$sc-checkbox-theme: mat.define-light-theme((
    color: (
        primary: $sc-checkbox-primary,
        accent: $sc-checkbox-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0
));

@include mat.checkbox-theme($sc-checkbox-theme);