@import "src/assets/scss/variable";

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: $radius;
}
