@import "src/assets/scss/variable";

/*******************
Rounds
*******************/

.round {
  line-height: 45px;
  color: $white;
  width: 45px;
  height: 45px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: $blue-base;

  img {
    border-radius: 100%;
  }
}

.round.round-info {
  background: $blue-base;
}

.round.round-warning {
  background: $orange-lighten-30;
}

.round.round-danger {
  background: $red-lighten-15;
}

.round.round-success {
  background: $green-lighten-30;
}

.round.round-primary {
  background: $primary;
}
