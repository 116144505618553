@import "src/assets/scss/variable";

/*Badge*/

.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: $green-lighten-30;
}

.badge-info {
  background-color: $blue-base;
}

.badge-primary {
  background-color: $primary;
}

.badge-warning {
  background-color: $orange-lighten-30;
  color: $white;
}

.badge-danger {
  background-color: $red-lighten-15;
}

.badge-purple {
  background-color: $purple;
}

.badge-red {
  background-color: $red-lighten-15;
}

.badge-inverse {
  background-color: $gray-darken-40;
}
