@font-face {
  font-family: "metropolis-light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Metropolis/Webfonts/WOFF2/Metropolis-Light.woff2")
      format("woff2"),
    url("../fonts/Metropolis/Webfonts/WOFF/Metropolis-Light.woff")
      format("woff"),
    url("../fonts/Metropolis/TrueType/Metropolis-Light.ttf") format("truetype"),
    url("../fonts/Metropolis/Metropolis-Light.otf") format("otf");
}
@font-face {
  font-family: "metropolis-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Metropolis/Webfonts/WOFF2/Metropolis-Regular.woff2")
      format("woff2"),
    url("../fonts/Metropolis/Webfonts/WOFF/Metropolis-Regular.woff")
      format("woff"),
    url("../fonts/Metropolis/Metropolis-Regular.otf") format("otf");
}
@font-face {
  font-family: "Metropolis-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Metropolis/Webfonts/WOFF2/Metropolis-Medium.woff2")
      format("woff2"),
    url("../fonts/Metropolis/Webfonts/WOFF/Metropolis-Medium.woff")
      format("woff"),
    url("../fonts/Metropolis/Metropolis-Medium.otf") format("otf");
}
@font-face {
  font-family: "Metropolis-SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Metropolis/Webfonts/WOFF2/Metropolis-SemiBold.woff2")
      format("woff2"),
    url("../fonts/Metropolis/Webfonts/WOFF/Metropolis-SemiBold.woff")
      format("woff"),
    url("../fonts/Metropolis/Metropolis-SemiBold.otf") format("otf");
}
@font-face {
  font-family: "metropolis-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Metropolis/Webfonts/WOFF2/Metropolis-Bold.woff2")
      format("woff2"),
    url("../fonts/Metropolis/Webfonts/WOFF/Metropolis-Bold.woff") format("woff"),
    url("../fonts/Metropolis/Metropolis-Bold.otf") format("otf");
}
