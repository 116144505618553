@import "src/assets/scss/variable";

strong {
  font-weight: bold;
  font-family: "metropolis-bold", sans-serif;
}

.icon-c-question {
  position: relative;
  top: 2px;
  left: 2px;
  &:hover {
    cursor: pointer;
  }
}

body {
  * {
    font-family: $regularfont;
    color: #171b1f;
  }
}
.font {
  &.light {
    font-family: $regularfont;
  }
  &.regular {
    font-family: $regularfont;
  }
  &.medium {
    font-family: $mediumfont;
  }
  &.semi-bold {
    font-family: $semi-boldfont;
  }
  &.bold {
    font-family: $boldfont;
  }
}
@mixin typography($ffamily, $fsize, $lheight, $color) {
  font-family: $ffamily;
  font-size: $fsize;
  line-height: $lheight;
  color: $color;
}
.h1 {
  @include typography($semi-boldfont, 64px, 72px, $gray-base);
}
.h2 {
  @include typography($semi-boldfont, 48px, 56px, $gray-darken-40);
}
.h3 {
  @include typography($semi-boldfont, 40px, 48px, $gray-base);
}
.h1subheadline {
  @include typography($mediumfont, 24px, 32px, $gray-base);
}
.h2subheadline {
  @include typography($bodyfont, 20px, 30px, $gray-base);
}
.h4 {
  @include typography($bodyfont, 24px, 36px, $gray-base);
}
.h6 {
  @include typography($bodyfont, 20px, 30px, $gray-base);
}
.card-title {
  @include typography($mediumfont, 18px, 27px, $gray-base);
}
.ghostBody {
  @include typography($bodyfont, 16px, 16px, $gray-lighten-40);
}
.largeBody {
  @include typography($bodyfont, 18px, 28px, $gray-darken-40);
}
.regularBody {
  @include typography($bodyfont, 16px, 24px, $gray-darken-40);
}
.modalTitle {
  @include typography($bodyfont, 24px, 32px, $gray-darken-40);
}
.card-title2 {
  @include typography($bodyfont, 20px, 32px, $gray-darken-40);
}
.modalsubHeader {
  @include typography($bodyfont, 16px, 24px, $gray-base);
}
.smallBody {
  @include typography($bodyfont, 14px, 22px, $gray-base);
}
.caption {
  @include typography($bodyfont, 12px, 18px, $gray-base);
}
.dark-text {
  @include typography($bodyfont, 14px, 22px, $gray-darken-40);
}
.lighter-l-text {
  @include typography($bodyfont, 16px, 24px, $gray-darken-20);
}
.lighter-text {
  @include typography($bodyfont, 14px, 24px, $gray-darken-20);
}
.lighter-text-2 {
  @include typography($bodyfont, 14px, 22px, $gray-darken-10);
}
.lighter-text-3 {
  @include typography($mediumfont, 16px, 22px, $gray-darken-10);
}
.dark-title {
  @include typography($mediumfont, 24px, 32px, $gray-darken-40);
}
.dark-title-text {
  @include typography($mediumfont, 14px, 22px, $gray-darken-40);
}
.dark-title-text-2 {
  @include typography($mediumfont, 20px, 32px, $gray-darken-40);
}
.dark-title-text-3 {
  @include typography($mediumfont, 12px, 16px, $gray-darken-40);
}
.icon {
  color: $gray-lighten-20;
}
.blueLink {
  color: $blue-base;
}
.page-title {
  @include typography($regularfont, 24px, 32px, $gray-base);
  margin-bottom: 40px;
}
.text-red {
  @include typography($bodyfont, 12px, 18px, $red-base);
}
.label-text {
  @include typography($mediumfont, 14px, 24px, $gray-lighten-20);
}

// Page heading custom styles
// Page title styles
.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

/*******************
font weight
*******************/

html body {
  .font-bold {
    font-weight: 700;
  }

  .font-normal {
    font-weight: normal;
  }

  .font-light {
    font-weight: 300;
  }

  .font-medium {
    font-weight: 400;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }
}

/*******************
Text Colors
*******************/

html body {
  .text-muted {
    color: $gray-lighten-30;
  }
}

/*Text Color*/

.text-white {
  color: $white;
}

.text-warning {
  color: $orange-lighten-30;
}

.text-success {
  color: $green-lighten-30;
}

.text-info {
  color: $blue-base;
}

.text-inverse {
  color: $gray-darken-40;
}

.text-blue {
  color: $blue;
}

.text-purple {
  color: $purple;
}

.text-primary {
  color: $primary;
}

.text-megna {
  color: $teal;
}

.text-dark {
  color: $gray-base;
}

.text-themecolor {
  color: $blue-base;
}
