@import "src/assets/scss/variable";

/*******************
Blockquote
*******************/

blockquote {
  border: 1px solid $border;
  border-left: 5px solid $blue-base;
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}
