@import "src/assets/scss/variable";

/*******************
Notify
*******************/

.notify {
  position: relative;
  top: -25px;
  right: -7px;

  .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid $red-lighten-15;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: $red-lighten-15;
    position: absolute;
    right: 6px;
    top: -10px;
  }
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
