@import "src/assets/scss/variable";

va-mat-table {
  display: block;
  width: 100%;
  font-size: 1rem;
}

// Main table styles
table,
.mat-mdc-table {
  width: 100%;
  font-family: "metropolis-regular", sans-serif;
  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }

  thead {
    background-color: $gray-lighten-70;

    tr {
      height: auto;
      th .mat-sort-header-content,
      .mat-sort-header-content span {
        font-family: $semi-boldfont;
        color: $gray-darken-40;
      }
      th,
      th.mat-mdc-header-cell {
        background-color: $gray-lighten-70;
        border-bottom-color: rgba(0, 0, 0, 0.12);
        border-right: none !important;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        position: relative;
        font-family: $semi-boldfont;
        // font-weight: 600; Too bold in FF and Safari
        font-stretch: normal;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: normal;
        color: $gray-darken-40 !important;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        &:not(:last-child) {
          .resize-holder {
            cursor: col-resize;
            width: 20px;
            height: 100%;
            position: absolute;
            right: -10px;
            top: 0;
            z-index: 1;
          }
        }
        &:not(:nth-child(1)) {
          padding: 10px 5px;
        }
      }
    }
  }

  tbody {
    background-color: $white;
    * {
      color: $classtype-1;
    }

    & > tr {
      border: none;
      cursor: pointer;

      &.mat-mdc-header-row {
        height: auto;
      }

      td {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 140px;
        .dark-text {
          color: $gray-darken-40 !important;
        }
        &.mat-mdc-cell {
          font-size: 14px;
          border-bottom-color: rgba(0, 0, 0, 0.12);
        }

        & > div.truncate-two-lines {
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
          color: inherit;
        }
      }
      &:hover {
        background-color: $gray-lighten-60;
      }
    }
  }
}

// Table checkbox styles
.mat-mdc-checkbox {
  &-indeterminate {
    &.mat-accent {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-background {
        background-color: $blue-base;
        border: 1px solid $blue-base;
      }
    }
  }
  &-checked {
    &.mat-accent {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-background {
        background-color: $blue-base;
        border: 1px solid $blue-base;
      }
    }
  }
}

// Row styles
.checked-row {
  background-color: $gray-lighten-60;
}

.mat-mdc-outlined-button {
  border: none !important;
}

/*******************
Pagination
*******************/

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: $lft;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: $blue-base;
  border-color: $blue-base;
}

.pager li > a,
.pager li > span {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
  color: $gray-darken-40;
}

/*******************
Table Cell
*******************/

.table-box {
  display: table;
  width: 100%;
}

.table.no-border {
  tbody {
    td {
      border: 0px;
    }
  }
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.table td,
.table th {
  border-color: $table-border;
}

.table thead th,
.table th {
  font-weight: 500;
}

.table-hover tbody tr:hover {
  background: $blue-lighten-95;
}

html body {
  .jqstooltip {
    width: auto;
    height: auto;
  }
}

.v-middle {
  td,
  th {
    vertical-align: middle;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-edit-buttons {
  margin: 2vh 0;
  float: right;
  clear: both;
}
