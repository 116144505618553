@import "src/assets/scss/variable";

@mixin button {
  display: inline-block;
  padding: 8px 24px;
  margin: 0 10px 20px 10px;
  min-width: 105px;
  width: auto;
  height: 40px;
  font-family: $semi-boldfont;
  font-size: 16px;
  cursor: pointer;
  border-radius: 100px;
  user-select: none;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;

  &.-cancel {
    background-color: transparent;
    color: $gray-lighten-20;
    border: none;
  }
  &.-nostylebtn {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: transparent;
  }

  &.-secondary {
    background-color: transparent;
    color: $gray-lighten-30;
    border: 2px solid $gray-lighten-30;

    &.-alternative {
      color: $blue-base;
      border-color: $blue-base;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:focus,
      &:hover,
      &:active {
        color: $blue-base;
        border-color: $blue-base;
      }
    }

    &:focus,
    &:hover {
      color: $gray-lighten-20;
      border: 2px solid $gray-lighten-20;
    }

    &:active {
      color: $gray-lighten-10;
      border: 2px solid $gray-lighten-10;
    }
  }

  &.-primary {
    background-color: $blue-base;
    color: $pure-white;
    border: 2px solid $blue-base;

    &:focus {
      box-shadow: 0 0 4px #308deb;
    }
    &:hover {
      background-color: $blue-darken-10;
      border: 2px solid $blue-darken-10;
    }

    &:active {
      background-color: $blue-darken-30;
      border: 2px solid $blue-darken-30;
    }

    &:disabled {
      background-color: $blue-lighten-30;
      cursor: not-allowed;
      border: 2px solid $blue-lighten-30;
    }
  }

  &.-allowBtn {
    background: $green-darken-15;
    border: 1px solid $green-darken-15;
    color: $pure-white;
    font-size: 14px;

    &:focus,
    &:hover {
      background-color: $green-darken-30;
      border: 1px solid $green-darken-30;
    }

    &:active {
      background-color: $green-darken-45;
      border: 1px solid $green-darken-45;
    }

    &:disabled {
      background-color: $green-lighten-75;
      border: 1px solid $green-lighten-75;
      cursor: not-allowed;
    }
  }

  &.-primaryAllow {
    background-color: $pure-white;
    color: $green-base;
    border: 1px solid $green-base;

    &:focus,
    &:hover {
      background-color: $pure-white;
    }

    &:active {
      background-color: $pure-white;
    }
  }

  &.-primaryBlock {
    background-color: $pure-white;
    color: $red-base;
    border: 1px solid $red-base;

    &:focus,
    &:hover {
      background-color: $pure-white;
    }

    &:active {
      background-color: $pure-white;
    }
  }

  &.-primaryBlocked {
    background-color: $red-base;
    color: $pure-white;
    border: 1px solid $red-base;

    &:focus,
    &:hover {
      background-color: $red-darken-15;
      border: 1px solid $red-darken-15;
    }

    &:active {
      background-color: $red-darken-30;
      border: 1px solid $red-darken-30;
    }

    &:disabled {
      background-color: $red-lighten-75;
      border: 1px solid $red-lighten-75;
      cursor: not-allowed;
    }
  }

  &.-primary-bg {
    background-color: transparent;
    color: $blue-base;
    border: 2px solid $blue-base;

    &:focus,
    &:hover {
      border: 2px solid $blue-darken-15;
      color: $blue-darken-15;
    }

    &:active {
      border: 2px solid $blue-darken-30;
      color: $blue-darken-30;
    }
    &:disabled {
      border: 2px solid $blue-lighten-75;
      color: $blue-lighten-75;
      cursor: not-allowed;
    }
  }

  &.-secondary-bg {
    border: 1px solid $pure-white;
    background-color: transparent;
    color: $pure-white;

    &:focus,
    &:hover {
      background-color: $pure-white;
      color: $blue-base;
    }

    &:active {
      background-color: $pure-white;
      color: $blue-base;
    }
  }

  &.-disable {
    border: 2px solid $blue-lighten-75;
    background-color: $blue-lighten-75;
    color: $pure-white;

    &:focus,
    &:hover {
      border: 2px solid $blue-lighten-75;
      background-color: $pure-white;
      color: $blue-lighten-75;
    }
  }
}

@mixin small {
  padding: 5px 10px;
  min-width: 144px;
  line-height: normal;
  font-size: 14px;
  height: 32px;
  &.-disable {
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  &.-disabled {
    border: 1px solid $gray-lighten-40;
    background-color: transparent;
    color: $gray-lighten-40;

    &:focus,
    &:hover {
      border: 1px solid $gray-lighten-40;
      background-color: transparent;
      color: $blue-lighten-75;
    }
  }
}

@mixin x-small {
  display: inline-block;
  font-family: $semi-boldfont;
  cursor: pointer;
  border-radius: 20px;
  user-select: none;
  text-align: center;
  height: auto;
  line-height: 20px;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  padding: 3px 0;
  width: 92px;
  height: 32px;

  &.-primaryAllow {
    background-color: $pure-white;
    color: $green-base;
    border: 1px solid $green-base;

    &:focus,
    &:hover {
      background-color: $pure-white;
    }

    &:active {
      background-color: $pure-white;
    }
  }

  &.-primaryBlock {
    background-color: $pure-white;
    color: $red-lighten-15;
    border: 1px solid $red-lighten-15;

    &:focus,
    &:hover {
      background-color: $pure-white;
    }

    &:active {
      background-color: $pure-white;
    }
  }

  &.-disable {
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  &.-disabled {
    border: 1px solid $gray-lighten-40;
    background-color: transparent;
    color: $gray-lighten-40;

    &:focus,
    &:hover {
      border: 1px solid $gray-lighten-40;
      background-color: transparent;
      color: $gray-lighten-40;
    }
  }
}
.-nostylebtn {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  position: relative;
}

.button {
  @include button;
}

button {
  &:focus {
    outline: none !important;
  }
}

.small {
  @include small;
}

.x-small {
  @include x-small();
}

.bg-nutral {
  background: $green-base;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, $green-base, $blue-base);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, $green-base, $blue-base);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@mixin button-toggle {
  display: inline-block;
  padding: 10px 16px;
  margin: 0 10px 20px 10px;
  height: 44px;
  font-family: $semi-boldfont;
  font-size: 16px;
  cursor: pointer;
  border-radius: 22px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  user-select: none;
  text-align: center;
  height: auto;
  line-height: 20px;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;

  &.-primary {
    background-color: $blue-base;
    color: $pure-white;
    border: 2px solid $blue-base;

    &:focus,
    &:hover {
      background-color: $blue-darken-15;
      border: 2px solid $blue-darken-15;
    }

    &:active {
      background-color: $blue-darken-30;
      border: 2px solid $blue-darken-30;
    }

    &:disabled {
      background-color: $blue-lighten-75;
      cursor: not-allowed;
      border: 2px solid $blue-lighten-75;
    }
  }
}
@mixin button-report {
  display: inline-block;
  padding: 10px 16px;
  margin: 0 10px 20px 10px;
  height: 44px;
  font-family: $semi-boldfont;
  font-size: 16px;
  cursor: pointer;
  border-radius: 22px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  user-select: none;
  text-align: center;
  height: auto;
  line-height: 20px;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;

  &.-primary {
    background-color: $blue-base;
    color: $pure-white;
    border: 2px solid $blue-base;

    &:focus,
    &:hover {
      background-color: $blue-darken-15;
      border: 2px solid $blue-darken-15;
    }

    &:active {
      background-color: $blue-darken-30;
      border: 2px solid $blue-darken-30;
    }

    &:disabled {
      background-color: $blue-lighten-75;
      cursor: not-allowed;
      border: 2px solid $blue-lighten-75;
    }
  }
}

/*******************
Buttons
******************/
.non-sticy-btn {
  position: relative;
}

.sticky-btn {
  position: fixed;
  top: 56px;
  z-index: 1;
  width: 100%;
  left: -0.1em;
  background: $gray-lighten-70;
  padding: 8px 40px;
  text-align: right;
}

.overwriteBtn {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
}

.btn-group {
  &.btn-secondary {
    color: $gray-base;
  }
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.button-list button,
.button-list a {
  margin: 5px 12px 5px 0;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;

  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
  }

  &.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
  }
}

.btn-custom,
.btn-custom.disabled {
  background: $blue-base;
  border: 1px solid $blue-base;
  color: $white;

  &:hover {
    background: $blue-base;
    opacity: 0.8;
    color: $white;
    border: 1px solid $blue-base;
  }
}

.btn-secondary,
.btn-secondary.disabled {
  background: $gray-lighten-10;
  border: 1px solid $gray-lighten-10;
  color: $white;

  &:hover {
    background: $gray-lighten-10;
    opacity: 0.7;
    border: 1px solid $gray-lighten-10;
  }

  &.active,
  &:focus {
    background: $gray-lighten-10;
  }
}

.btn-primary,
.btn-primary.disabled {
  background: $blue-base;
  border: 1px solid $blue-base;

  &:hover {
    background: $blue-base;
    opacity: 0.7;
    border: 1px solid $blue-base;
  }

  &.active,
  &:focus {
    background: $blue-darken-30;
  }
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: $blue-base;
  color: $white;
  border: 1px solid $blue-base;

  &:hover {
    background: $blue-base;
    opacity: 0.7;
    border: 1px solid $blue-base;
  }

  &.active,
  &:focus {
    background: $blue-base;
  }
}

.btn-success,
.btn-success.disabled {
  background: $green-lighten-30;
  border: 1px solid $green-lighten-30;

  &:hover {
    background: $green-lighten-30;
    opacity: 0.7;
    border: 1px solid $green-lighten-30;
  }

  &.active,
  &:focus {
    background: $green-lighten-30;
  }
}

a:not([href]):not([tabindex]) {
  color: $blue-base;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $blue-base;
  }

  &:active {
    color: $blue-base;
  }
}

a {
  color: $blue-base;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $blue-base;
  }

  &:active {
    color: $blue-base;
  }
}

.btn-info,
.btn-info.disabled {
  background: $blue-base;
  border: 1px solid $blue-base;

  &:hover {
    background: $blue-base;
    opacity: 0.7;
    border: 1px solid $blue-base;
  }

  &.active,
  &:focus {
    background: $blue-base;
  }
}

.btn-warning,
.btn-warning.disabled {
  background: $orange-lighten-30;
  border: 1px solid $orange-lighten-30;
  color: $white;

  &:hover {
    background: $orange-lighten-30;
    color: $white;
    opacity: 0.7;
    border: 1px solid $orange-lighten-30;
  }

  &.active,
  &:focus {
    background: $orange-lighten-30;
  }
}

.btn-danger,
.btn-danger.disabled {
  background: $red-lighten-15;
  border: 1px solid $red-lighten-15;

  &:hover {
    background: $red-lighten-15;
    opacity: 0.7;
    border: 1px solid $red-lighten-15;
  }

  &.active,
  &:focus {
    background: $red-lighten-15;
  }
}

.btn-inverse,
.btn-inverse.disabled {
  background: $gray-darken-40;
  border: 1px solid $gray-darken-40;
  color: $white;

  &:hover {
    background: $gray-darken-40;
    opacity: 0.7;
    color: $white;
    border: 1px solid $gray-darken-40;
  }

  &.active,
  &:focus {
    background: $gray-darken-40;
    color: $white;
  }
}

.btn-red,
.btn-red.disabled {
  background: $red-lighten-15;
  border: 1px solid $red-lighten-15;
  color: $white;

  &:hover {
    opacity: 0.7;
    border: 1px solid $red-lighten-15;
    background: $red-lighten-15;
  }

  &.active,
  &:focus {
    background: $red-lighten-15;
  }
}

.btn-outline-default {
  background-color: $white;

  &:hover,
  &:focus,
  &.focus {
    background: $blue-lighten-95;
  }
}

.btn-outline-primary {
  color: $blue-base;
  background-color: $white;
  border-color: $blue-base;

  &:hover,
  &:focus,
  &.focus {
    background: $blue-base;
    color: $white;
    border-color: $blue-base;
  }
}

.btn-outline-success {
  color: $green-lighten-30;
  background-color: transparent;
  border-color: $green-lighten-30;

  &:hover,
  &:focus,
  &.focus {
    background: $green-lighten-30;
    border-color: $green-lighten-30;
    color: $white;
  }
}

.btn-outline-info {
  color: $blue-base;
  background-color: transparent;
  border-color: $blue-base;

  &:hover,
  &:focus,
  &.focus {
    background: $blue-base;
    border-color: $blue-base;
    color: $white;
  }
}

.btn-outline-warning {
  color: $orange-lighten-30;
  background-color: transparent;
  border-color: $orange-lighten-30;

  &:hover,
  &:focus,
  &.focus {
    background: $orange-lighten-30;
    border-color: $orange-lighten-30;
    color: $white;
  }
}

.btn-outline-danger {
  color: $red-lighten-15;
  background-color: transparent;
  border-color: $red-lighten-15;

  &:hover,
  &:focus,
  &.focus {
    background: $red-lighten-15;
    border-color: $red-lighten-15;
    color: $white;
  }
}

.btn-outline-red {
  color: $red-lighten-15;
  background-color: transparent;
  border-color: $red-lighten-15;

  &:hover,
  &:focus,
  &.focus {
    background: $red-lighten-15;
    border-color: $red-lighten-15;
    color: $white;
  }
}

.btn-outline-inverse {
  color: $gray-darken-40;
  background-color: transparent;
  border-color: $gray-darken-40;

  &:hover,
  &:focus,
  &.focus {
    background: $gray-darken-40;
    border-color: $gray-darken-40;
    color: $white;
  }
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: $blue-darken-30;
  border: 1px solid $blue-darken-30;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: $green-lighten-30;
  border: 1px solid $green-lighten-30;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: $blue-base;
  border: 1px solid $blue-base;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: $orange-lighten-30;
  border: 1px solid $orange-lighten-30;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: $red-lighten-15;
  border: 1px solid $red-lighten-15;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: $gray-darken-40;
  border: 1px solid $gray-darken-40;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: $red-base;
  border: 1px solid $red-base;
  color: $white;
}

.button-box .btn {
  margin: 0 8px 8px 0px;
}

.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px;
}

.btn-facebook {
  color: $white;
  background-color: #3b5998;
}

.btn-twitter {
  color: $white;
  background-color: #55acee;
}

.btn-linkedin {
  color: $white;
  background-color: #007bb6;
}

.btn-dribbble {
  color: $white;
  background-color: #ea4c89;
}

.btn-googleplus {
  color: $white;
  background-color: #dd4b39;
}

.btn-instagram {
  color: $white;
  background-color: #3f729b;
}

.btn-pinterest {
  color: $white;
  background-color: #cb2027;
}

.btn-dropbox {
  color: $white;
  background-color: #007ee5;
}

.btn-flickr {
  color: $white;
  background-color: #ff0084;
}

.btn-tumblr {
  color: $white;
  background-color: #32506d;
}

.btn-skype {
  color: $white;
  background-color: #00aff0;
}

.btn-youtube {
  color: $white;
  background-color: #bb0000;
}

.btn-github {
  color: $white;
  background-color: #171515;
}
.transparent {
  cursor: pointer;
  border: 1px solid transparent;
  background-color: transparent;
  box-shadow: 0 6px 6px $white;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    pointer-events: all;
  }
}
