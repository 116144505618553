@import "src/assets/scss/variable";

span.error-text {
  margin-top: 0.5rem;
  color: $red-base;
}

/*******************
Labels
*******************/

.label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-lighten-20;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: $teal;
}

.label-success {
  background-color: $green-lighten-30;
}

.label-info {
  background-color: $blue-base;
}

.label-warning {
  background-color: $orange-lighten-30;
}

.label-danger {
  background-color: $red-lighten-15;
}

.label-megna {
  background-color: $teal;
}

.label-primary {
  background-color: $primary;
}

.label-purple {
  background-color: $purple;
}

.label-red {
  background-color: $red-base;
}

.label-inverse {
  background-color: $gray-darken-40;
}

.label-default {
  background-color: $blue-lighten-95;
}

.label-white {
  background-color: $white;
}

.label-light-success {
  color: $green-lighten-15;
}

.label-light-info {
  background-color: $blue-lighten-90;
  color: $blue-base;
}

.label-light-warning {
  background-color: $orange-lighten-90;
  color: $orange-lighten-30;
}

.label-light-danger {
  background-color: $red-lighten-90;
  color: $red-lighten-15;
}

.label-light-megna {
  background-color: $blue-lighten-95;
  color: $teal;
}

.label-light-primary {
  background-color: $blue-lighten-95;
  color: $primary;
}

.label-light-inverse {
  background-color: $red-lighten-95;
  color: $gray-darken-40;
}

/*******************
Form-control
******************/
.placeholder {
  color: $gray-lighten-40;
  opacity: 1;
}
.filterControl {
  background-color: transparent;
  margin-bottom: 8px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid $gray-lighten-40;
  border-radius: 0;
  font-size: 14px;
  line-height: 22px;
  color: $gray-lighten-40;
  padding: 0 24px;
  &:focus {
    background-color: transparent;
    color: $gray-base;
    border: transparent;
    border-bottom: 1px solid $green-base;
    outline: 0;
    box-shadow: none;
  }
  &::-webkit-input-placeholder {
    /* Edge */
    color: $gray-darken-10 !important;
    font-family: $regularfont;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray-darken-10 !important;
    font-family: $regularfont;
  }

  &::placeholder {
    color: $gray-darken-10 !important;
    font-family: $regularfont;
  }
}

.form-control {
  color: $gray-darken-40;
  min-height: 37px;
  display: initial;

  &::placeholder {
    color: $gray-lighten-40;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray-lighten-40;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray-lighten-40;
  }
}

.form-control.is-invalid {
  border-bottom: 1px solid #e60f00;
  background: none;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.form-error {
  font-size: 12px;
  line-height: 2;
  color: $red-base;
}

.form-error-url {
  font-size: 12px;
  line-height: 2;
  color: $blue-base;

  &:hover {
    color: $blue-base;
  }

  &:focus {
    color: $blue-base;
  }
}

.form-control-sm {
  min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f7fafc;
  color: $gray-base;
}

.mat-form-field-invalid .mat-mdc-input-element,
.mat-warn .mat-input-element {
  caret-color: $gray-darken-40;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: $green-lighten-30;
}

form label {
  font-weight: 400;
}

.form-group {
  margin-bottom: 25px;
}

.form-horizontal label {
  margin-bottom: 0px;
}

.form-control-static {
  padding-top: 0px;
}

.form-bordered .form-group {
  border-bottom: 1px solid $border;
  padding-bottom: 20px;
}

.form-control:focus {
  color: $gray-darken-40;
  border-color: #24d4bb;
  outline: 0;
  box-shadow: none;
}

// Validation
.ng-invalid.ng-touched:not(form) {
  border-left: 4px solid theme-color("danger");
}

.required-before:before {
  content: "* ";
  color: $red-base;
  font-size: 16px;
}
.required::after {
  content: "* ";
  color: $gray-base;
}

.input-group {
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    background-color: unset;
    border-bottom: 1px solid $gray-lighten-40;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: $gray-lighten-40;
  }
  input {
    padding-left: 1rem;
  }
}
