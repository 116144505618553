@import "src/assets/scss/variable";

/*******************
Wave Effects
*******************/

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
  }
}

.waves-effect.waves-light {
  .waves-ripple {
    background-color: rgba(255, 255, 255, 0.45);
  }
}

.waves-effect.waves-red {
  .waves-ripple {
    background-color: rgba(244, 67, 54, 0.7);
  }
}

.waves-effect.waves-yellow {
  .waves-ripple {
    background-color: rgba(255, 235, 59, 0.7);
  }
}

.waves-effect.waves-orange {
  .waves-ripple {
    background-color: rgba(255, 152, 0, 0.7);
  }
}

.waves-effect.waves-purple {
  .waves-ripple {
    background-color: rgba(156, 39, 176, 0.7);
  }
}

.waves-effect.waves-green {
  .waves-ripple {
    background-color: rgba(76, 175, 80, 0.7);
  }
}

.waves-effect.waves-teal {
  .waves-ripple {
    background-color: rgba(0, 150, 136, 0.7);
  }
}

html body {
  .waves-notransition {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
  }
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;

  .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.waves-block {
  display: block;
}
