@import "src/assets/scss/variable";

.action-bar {
  background: $white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  text-align: right;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 0.25em 1em;
  height: 48px;
  .filter {
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 14px;
  }

  &:empty {
    display: none;
    padding: 0.25em 1.5em;

    &:empty {
      display: none;
    }
  }

  &.sub-nav {
    padding: 0;
  }

  .filter-section {
    background-color: $white;
    border-radius: 4px;
    align-items: center;
    display: flex;

    .filter-icon {
      display: flex;
      margin-left: 60px;
      position: absolute;
      right: 3vw;

      .btn-light {
        color: #212529;
        background-color: transparent;
        border-color: transparent;
      }
      mat-icon,
      i {
        vertical-align: text-bottom;
      }
      .settings-gear {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }

      button:last-of-type {
        position: relative;
        // top: -0.33vh;
        padding-left: 0;
      }
      i {
        font-size: 20px;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .filterControl.search,
  .filterControl.reportSearch {
    width: 29em;
  }
  .delete {
    font-size: 14px;
    padding: 0.2rem 0;
    background-color: $white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;

    &-text {
      margin-right: 1rem;
      font-size: 16px;
      display: flex;
      margin-left: 10px;
    }

    &-icon {
      padding-right: 0.5em;
    }
  }

  .filter-components {
    display: flex;
    align-items: flex-start;
  }
}
.sub-nav-tab {
  a.mat-mdc-tab-link {
    font-family: $mediumfont;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  a.mat-tab-label-active {
    background-color: transparent;
    color: $gray-darken-40;
    font-family: $mediumfont;
    border-bottom: 3px solid $blue-base;
    font-weight: 500;
    opacity: 1;
    &:hover,
    &:focus {
      background-color: transparent !important;
      color: $gray-darken-40;
    }
  }
}
.sub-nav,
.action-bar {
  .mat-mdc-tab-header {
    border-bottom: 0;
  }
  .mat-ink-bar {
    height: 3px;
    background-color: $blue-base !important;
  }
}
