@import "src/assets/scss/variable";

$numColumns-lg: 24;
$numColumns: 12;
$numColumns-tablet: 8;
$numColumns-mobile: 4;
$desktop-big-width: 1920px;
$desktop-width: 1440px;
$desktop-margin: 0 36px 36px;
$big-desktop-margin: 0 40px 40px;
$gutter: 0 24px;
$tablet-width: 768px;
$tablet-small-width: 767px;
$tablet-margin: 0 24px;
$mobile-width: 320px;
$tablet-min: $mobile-width + 1px;

@mixin clearfix {
  &:before,
  :after {
    clear: both;
    contain: "";
    display: table;
  }
}

@media screen and (-ms-hight-contrast: active), (-ms-high-contrast: none) {
  .rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    &.left {
      margin: initial;
    }

    @media screen and (max-width: $mobile-width) {
      max-width: 320px;
    }

    @media screen and (max-width: $tablet-width) and (min-width: $tablet-min) {
      max-width: 768px;
    }

    @media screen and (max-width: $desktop-width) and (min-width: $tablet-width) {
      max-width: 1440px;
    }

    @for $i from 1 through $numColumns {
      .columns-#{$i} {
        flex: 0 0 auto;

        @media screen and (max-width: $mobile-width) {
          @if ($i >=$numColumns-mobile) {
            width: 100%;
          } @else {
            $horz-spacing: 24px;
            $g: calc($numColumns-mobile * $numColumns-mobile/100);
            $width: percentage(calc(100 / $numColumns-mobile) * calc($i/100));
            $gutter: calc(
              calc(
                  (
                      ($numColumns-tablet * $horz-spacing) *
                        ($numColumns-tablet - $i)
                    ) /
                    100
                ) /
                $g
            );
            width: calc(#{$width} - #{$gutter});
          }
        }

        @media screen and (max-width: $tablet-width) and (min-width: $tablet-min) {
          @if ($i >=$numColumns-tablet) {
            width: 100%;
          }

          $horz-spacing: 24px;
          $g: calc($numColumns-tablet * $numColumns-tablet/100);
          $width: percentage(calc(100 / $numColumns-tablet) * calc($i/100));
          $gutter: calc(
            calc(
                (
                    ($numColumns-tablet * $horz-spacing) *
                      ($numColumns-tablet - $i)
                  ) / 100
              ) / $g
          );
          width: calc(#{$width} - #{$gutter});
        }

        @media screen and (max-width: 1440px) and (min-width: 768px) {
          $horz-spacing: 24px;
          $g: calc($numColumns * $numColumns/100);
          $width: percentage(calc(100 / $numColumns) * calc($i/100));
          $gutter: calc(
            calc((($numColumns * $horz-spacing) * ($numColumns - $i)) / 100) /
              $g
          );
          width: calc(#{$width} - #{$gutter});

          @if ($i >=$numColumns) {
            width: calc(100% - (#{$gutter}* 0));
          }
        }
      }
    }
  }
}

@mixin columns($i) {
  @if $i from 1 to $numColumns {
    grid-column: span $i;
    -ms-grid-column-span: $i;
    margin-right: 0;
  }
}

@supports (display: grid) {
  .rows {
    display: grid;
    grid-template-columns: repeat($numColumns, 1fr);
    grid-gap: $gutter;

    @for $i from 1 through $numColumns {
      .columns-#{$i} {
        grid-column: span $i;
        -ms-grid-column-span: $i;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: $desktop-width) and (min-width: $tablet-width) {
    .rows {
      grid-template-columns: repeat($numColumns, 1fr);
      grid-gap: $gutter;

      @for $i from 1 through $numColumns {
        .columns-#{$i} {
          grid-column: span $numColumns;
          -ms-grid-column-span: $numColumns;
          margin-right: 0;
        }

        @if $i from 1 to 11 {
          .columns-11 {
            @include columns(11);
          }

          .columns-10 {
            @include columns(10);
          }

          .columns-9 {
            @include columns(9);
          }

          .columns-8 {
            @include columns(8);
          }

          .columns-7 {
            @include columns(7);
          }

          .columns-6 {
            @include columns(6);
          }

          .columns-5 {
            @include columns(5);
          }

          .columns-4 {
            @include columns(4);
          }

          .columns-3 {
            @include columns(3);
          }

          .columns-2 {
            @include columns(2);
          }

          .columns-1 {
            @include columns(1);
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-width) and (min-width: $tablet-min) {
    .rows {
      grid-template-columns: repeat($numColumns-tablet, 1fr);
      grid-gap: $gutter;

      @for $i from 1 through $numColumns {
        .columns-#{$i} {
          grid-column: span $numColumns-tablet;
          -ms-grid-column-span: $numColumns-tablet;
          margin-right: 0;
        }

        @if $i from 1 through 7 {
          .columns-7 {
            @include columns(7);
          }

          .columns-6 {
            @include columns(6);
          }

          .columns-5 {
            @include columns(5);
          }

          .columns-4 {
            @include columns(4);
          }

          .columns-3 {
            @include columns(3);
          }

          .columns-2 {
            @include columns(2);
          }

          .columns-1 {
            @include columns(1);
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-width) {
    .rows {
      grid-template-columns: repeat($numColumns-mobile, 1fr);
      grid-gap: $gutter;

      @for $i from 1 through $numColumns {
        .columns-#{$i} {
          grid-column: span $numColumns-mobile;
          -ms-grid-column-span: $numColumns-mobile;
          margin-right: 0;
        }

        @if $i from 1 through 3 {
          .columns-3 {
            @include columns(3);
          }

          .columns-2 {
            @include columns(2);
          }

          .columns-1 {
            @include columns(1);
          }
        }
      }
    }
  }
}

@mixin grid__container {
  @include clearfix;
  margin: $big-desktop-margin;

  @media screen and (max-width: $desktop-big-width) and (min-width: $tablet-width) {
    margin: $desktop-margin;
  }

  @media screen and (max-width: $tablet-width) and (min-width: $tablet-min) {
    margin: $tablet-margin;
  }

  @media screen and (max-width: $mobile-width) {
    margin: $tablet-margin;
  }
}

.grid_container {
  @include grid__container;
}

@mixin cc-tablet($grids...) {
}

.rows {
  @include cc-tablet(2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
}

// .box{
//   margin-bottom: 16px;
//   width: 100%;
//   min-height: 75px;
//   background: grey;
//   border-radius: 4px;
//   font-size: 12px;
//   text-align: center;
// }
