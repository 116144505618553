/*typography*/
$bodyfont: "metropolis-regular", sans-serif;
$headingfont: "metropolis-regular", sans-serif;
$lightfont: "metropolis-light", sans-serif;
$regularfont: "metropolis-regular", sans-serif;
$mediumfont: "Metropolis-Medium", sans-serif;
$semi-boldfont: "Metropolis-SemiBold", sans-serif;
$boldfont: "metropolis-bold", sans-serif;

/*Normal Color*/
$pure-white: #ffffff;
$black: #000000;

/*Theme Colors*/
$orange: #ff8a50;
$dark-blue: #6169ef;
$teal: #02d7ef;
$purple-dark: #6a3ab2;
$green: #1dad76;
$purple: #a94dea;
$dark-pink: #ff7f7d;
$blue: #2e73db;
$pink: #ef629d;

/*Blue Colors*/
$blue-darken-60: #00264d;
$blue-darken-45: #003973;
$blue-darken-30: #004d99;
$blue-darken-15: #0060bf;
$blue-darken-10: #005dba;
$blue-base: #0073e6;
$blue-lighten-15: #2693ff;
$blue-lighten-30: #8dc1f4;
$blue-lighten-45: #73b9ff;
$blue-lighten-50: #e2effc;
$blue-lighten-60: #f6fafe;
$blue-lighten-75: #bfdfff;
$blue-lighten-90: #e6f2ff;
$blue-lighten-95: #f2f9ff;

/*Green Colors*/
$green-darken-60: #004d3b;
$green-darken-45: #007358;
$green-darken-30: #009976;
$green-darken-15: #00bf93;
$green-base: #00e6b1;
$green-lighten-15: #23ebbd;
$green-lighten-30: #48f0c9;
$green-lighten-45: #6ef5d6;
$green-lighten-60: #94f7e1;
$green-lighten-75: #bbfaec;
$green-lighten-90: #e6fff9;
$green-lighten-95: #f2fffc;

/*Red Colors*/
$red-darken-60: #4d0500;
$red-darken-45: #730800;
$red-darken-30: #990a00;
$red-darken-15: #bf0d00;
$red-base: #e60f00;
$red-lighten-15: #ff3526;
$red-lighten-30: #ff584d;
$red-lighten-45: #ff7c73;
$red-lighten-60: #ffa099;
$red-lighten-75: #ffc3bf;
$red-lighten-90: #ffe7e6;
$red-lighten-95: #fff3f2;

/*Orange Colors*/
$orange-darken-60: #4d2600;
$orange-darken-45: #733900;
$orange-darken-30: #994d00;
$orange-darken-15: #bf6000;
$orange-base: #e67300;
$orange-lighten-15: #ff9326;
$orange-lighten-30: #ffa64d;
$orange-lighten-45: #ffb973;
$orange-lighten-60: #ffcc99;
$orange-lighten-75: #ffdfbf;
$orange-lighten-90: #fff2e6;
$orange-lighten-95: #fff9f2;

/*Yellow Colors*/
$yellow-darken-60: #4d4200;
$yellow-darken-45: #736300;
$yellow-darken-30: #998500;
$yellow-darken-15: #bfa600;
$yellow-base: #e6c700;
$yellow-lighten-15: #ffe226;
$yellow-lighten-30: #ffe74d;
$yellow-lighten-45: #ffec73;
$yellow-lighten-60: #fff199;
$yellow-lighten-75: #fff7bf;
$yellow-lighten-90: #fffce6;
$yellow-lighten-95: #fffdf2;

/*Gray Colors*/
$gray-darken-40: #171b1f;
$gray-darken-30: #2a3138;
$gray-darken-20: #3d4852;
$gray-darken-10: #505e6b;
$gray-base: #637484;
$gray-lighten-10: #778b9e;
$gray-lighten-20: #8aa1b8;
$gray-lighten-30: #9db7d1;
$gray-lighten-40: #b0cceb;
$gray-lighten-50: #d2e4f7;
$gray-lighten-60: #edf3fa;
$gray-lighten-70: #f7fafc;

/*font weight*/
$normal: 400;
$light: 300;
$medium: 500;
$semi-bold: 600;
$bold: 700;

/*bootstrap Color*/
$primary: #7460ee;
$primary-dark: #6352ce;
$perwinkle: #9a9ff4;
$light-primary: #f1effd;

/*Normal Color*/
$white: #ffffff;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
$classtype-1: #505e6b;
$classtype-2: #ffe226;
$classtype-3: #614dff;
$classtype-4: #4da6ff;
$classtype-5: #ff9326;
$classtype-6: #00bf93;
$classtype-7: #ff3526;
$classtype-8: #bfa600;
/*Preloader*/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
  .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
  }
}
// Common variables
$header-height: 48px;
